import i18next from 'i18next';

import { ACTIONS } from './constants';

i18next.addResources('es', 'ValidateAffiliation', {
  [`${ACTIONS.ACCEPTED.key}Title`]: '¡Muchas gracias por tu confirmación!',
  [`${ACTIONS.ACCEPTED.key}Text`]: 'Tu solicitud de afiliación continúa en proceso. Si necesitás realizar una consulta, podés contactarte con tu asesor comercial.',
  [`${ACTIONS.REJECTED.key}Title`]: 'Solicitud rechazada',
  [`${ACTIONS.REJECTED.key}Text`]: `Para corregir o modificar los datos ingresados en la solicitud, por favor contactate con tu asesor comercial.

¡Muchas gracias!`
});
