import { SWORN_DECLARATION_FIELDS } from '~components/SwornDeclaration/constants';
import { GenericObjectInterface } from '~utils/types';

export const PATHOLOGY_TYPES = {
  HEPATITIS_A: 'hepatitis_a',
  HEPATITIS_B: 'hepatitis_b',
  HEPATITIS_C: 'hepatitis_c',
  DIABETES1: 'diabetes1',
  DIABETES2: 'diabetes2',
  HIV: 'hiv',
  HEREDITARY: 'hereditary',
  PSYCHIATRIC: 'psychiatric',
  ASMA: 'asma',
  ASMA_SEVERE: 'asmaSevere',
  EATING_DISORDERS: 'eatingDisorders',
  HYPOTHYROIDISM: 'hypothyroidism',
  DYSLIPEMIA: 'dyslipemia',
  EPILEPSY: 'epilepsy',
  EPILEPSY_REFRACTORY: 'epilepsyRefractory',
  CARDIAC: 'cardiac',
  HIPERTENSION: 'hipertension',
  HPV: 'hpv',
  AUDITION: 'audition',
  VISION: 'vision',
  NONE: 'none'
};

export const VISION_TYPES = {
  MYOPIA: 'myopia',
  ASTIGMATISM: 'astigmatism',
  HYPEROPIA: 'hyperopia',
  OTHER: 'other'
};

export const MYOPIA_TYPES = {
  LESS: 'lessThanSix',
  GREATER: 'greaterThanSix'
};

export const EXCLUDED_INITIAL_VALUES = [
  PATHOLOGY_TYPES.HEPATITIS_A,
  PATHOLOGY_TYPES.HEPATITIS_B,
  PATHOLOGY_TYPES.HEPATITIS_C,
  PATHOLOGY_TYPES.DIABETES1,
  PATHOLOGY_TYPES.DIABETES2,
  PATHOLOGY_TYPES.HIV,
  PATHOLOGY_TYPES.ASMA,
  PATHOLOGY_TYPES.ASMA_SEVERE,
  PATHOLOGY_TYPES.EATING_DISORDERS,
  PATHOLOGY_TYPES.HYPOTHYROIDISM,
  PATHOLOGY_TYPES.DYSLIPEMIA,
  PATHOLOGY_TYPES.EPILEPSY,
  PATHOLOGY_TYPES.EPILEPSY_REFRACTORY,
  PATHOLOGY_TYPES.CARDIAC,
  PATHOLOGY_TYPES.HIPERTENSION,
  PATHOLOGY_TYPES.HPV,
  PATHOLOGY_TYPES.VISION,
  PATHOLOGY_TYPES.NONE
];

export const WHICH_PATHOLOGY_TYPES_FIELDS: GenericObjectInterface<string> = {
  [PATHOLOGY_TYPES.HEREDITARY]: SWORN_DECLARATION_FIELDS.PATHOLOGY.WHICH_HEREDITARY,
  [PATHOLOGY_TYPES.PSYCHIATRIC]: SWORN_DECLARATION_FIELDS.PATHOLOGY.WHICH_PSYCHIATRIC,
  [PATHOLOGY_TYPES.AUDITION]: SWORN_DECLARATION_FIELDS.PATHOLOGY.WHICH_AUDITION,
  [PATHOLOGY_TYPES.VISION]: SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_VISION
};

export const SUBLABEL_HEIGHT = 22;

export const hadPathologyName = SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_PATHOLOGY;
export const hadVisionName = SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_VISION;
export const myopiaName = SWORN_DECLARATION_FIELDS.PATHOLOGY.WHICH_MYOPIA_VISION;
export const otherVisionName = SWORN_DECLARATION_FIELDS.PATHOLOGY.WHICH_OTHER_VISION;
