import { GenericObjectInterface } from '~utils/types';

export const QUERY_PARAMS = {
  TOKEN: 'token',
  ACTION: 'action',
  APPLICATION_ID: 'applicationId'
};

export const ACTIONS: GenericObjectInterface<{ key: string; value: boolean }> = {
  ACCEPTED: { key: 'accepted', value: false },
  REJECTED: { key: 'rejected', value: true }
};

export const ACEP_RECHA = {
  ACEPTADA: 'ACCEPTED',
  RECHAZADA: 'REJECTED'
};
