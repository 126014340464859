import React from 'react';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

interface Props {
  message: string;
  icon?: 'check' | 'info';
}

function StepSuccess({ message, icon = 'check' }: Props) {
  return (
    <div className={`row center ${styles.container} ${styles.widthWrap}`}>
      {icon === 'check' && <CheckCircleOutlined className={styles.icon} />}
      {icon === 'info' && <InfoCircleOutlined className={styles.icon} />}
      <span className={`text-light-normal text-color-primary ${styles.message}`}>{message}</span>
    </div>
  );
}

export default StepSuccess;
