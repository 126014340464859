import i18next from 'i18next';
import { sortByLabel } from '~utils/array';

export const GENDER_TYPE_VALUES = {
  FEMALE: 'female',
  MALE: 'male',
  NON_BINARY: 'nonBinary'
};

export const GENDER_OPTIONS = Object.values(GENDER_TYPE_VALUES).map(value => ({
  label: i18next.t(`SelectOptions:${value}`),
  value
}));

export const YES_NO_VALUES = {
  YES: i18next.t('SelectOptions:yes'),
  NO: i18next.t('SelectOptions:no')
};

export const YES_NO_OPTIONS = [
  { label: YES_NO_VALUES.YES, value: YES_NO_VALUES.YES },
  { label: YES_NO_VALUES.NO, value: YES_NO_VALUES.NO }
];

export const DOCUMENT_TYPE = [
  { label: i18next.t('DocumentType:dni'), value: 'dni' },
  { label: i18next.t('DocumentType:passport'), value: 'passport' }
];

export const DOCUMENT_TYPE_SOLO_ARGENTINOS = [{ label: i18next.t('DocumentType:dni'), value: 'dni' }];

export const COVERAGE_TYPE = [
  { label: 'Swiss Medical', value: 'Swiss Medical' },
  { label: 'Galeno', value: 'Galeno' },
  { label: 'Medicus', value: 'Medicus' },
  { label: 'Omint', value: 'Omint' },
  { label: 'Medifé', value: 'Medifé' },
  { label: 'Accord Salud', value: 'Accord Salud' },
  { label: 'Avalian', value: 'Avalian' },
  { label: 'Federada Salud', value: 'Federada Salud' },
  { label: 'Hospital Alemán', value: 'Hospital Alemán' },
  { label: 'Hospital Británico', value: 'Hospital Británico' },
  { label: 'Hospital Italiano', value: 'Hospital Italiano' },
  { label: 'Luis Pasteur', value: 'Luis Pasteur' },
  { label: 'Prevención Salud', value: 'Prevención Salud' },
  { label: 'Sancor Salud', value: 'Sancor Salud' },
  { label: 'William Hope', value: 'William Hope' },
  { label: 'ASE', value: 'ASE' },
  { label: 'HPC', value: 'HPC' },
  { label: 'INSEEP', value: 'INSEEP' },
  { label: 'IOMA', value: 'IOMA' },
  { label: 'IPROSS', value: 'IPROSS' },
  { label: 'ISSN', value: 'ISSN' },
  { label: 'OSECAC', value: 'OSECAC' },
  { label: 'OSAM', value: 'OSAM' },
  { label: 'OSCHOCA', value: 'OSCHOCA' },
  { label: 'OSDEPYM', value: 'OSDEPYM' },
  { label: 'OSDIPP', value: 'OSDIPP' },
  { label: 'OSMedica', value: 'OSMedica' },
  { label: 'OSPE', value: 'Ospe' },
  { label: 'OSPECON', value: 'OSPECON' },
  { label: 'OSPEGAP', value: 'OSPEGAP' },
  { label: 'OSPLAD', value: 'OSPLAD' },
  { label: 'OSPREPI', value: 'OSPREPI' },
  { label: 'OSPRERA', value: 'OSPRERA' },
  { label: 'OSUTHGRA', value: 'OSUTHGRA' },
  { label: 'SAMI', value: 'SAMI' },
  { label: 'OSDE', value: 'OSDE' },
  { label: 'Otra cobertura médica', value: 'Otra cobertura médica' },
  { label: 'Ninguna', value: 'Ninguna' }
];

export const PROFESSION = [
  { label: i18next.t('Profession:1'), value: 'AUTONOMO COMERCIAL - COMERCIANTE' },
  { label: i18next.t('Profession:2'), value: 'AUTONOMO COMERCIAL - REMATADORES' },
  { label: i18next.t('Profession:3'), value: 'AUTONOMO COMERCIAL - CORREDORES' },
  { label: i18next.t('Profession:4'), value: 'AUTONOMO COMERCIAL - GANADERO' },
  { label: i18next.t('Profession:5'), value: 'AUTONOMO COMERCIAL - AGRICULTOR' },
  { label: i18next.t('Profession:6'), value: 'AUTONOMO COMERCIAL - CONSTRUCTOR' },
  { label: i18next.t('Profession:7'), value: 'AUTONOMO PROFESIONAL - ABOGADO' },
  { label: i18next.t('Profession:8'), value: 'AUTONOMO PROFESIONAL - INGENIERO' },
  { label: i18next.t('Profession:9'), value: 'AUTONOMO PROFESIONAL - ARQUITECT' },
  { label: i18next.t('Profession:10'), value: 'AUTONOMO PROFESIONAL - CONTADOR' },
  { label: i18next.t('Profession:11'), value: 'AUTONOMO PROFESIONAL - MEDICO' },
  { label: i18next.t('Profession:12'), value: 'AUTONOMO PROFESIONAL - AGRONOMO' },
  { label: i18next.t('Profession:13'), value: 'AUTONOMO PROFESIONAL - VETERINAR' },
  { label: i18next.t('Profession:14'), value: 'AUTONOMO PROFESIONAL - ODONTOLOG' },
  { label: i18next.t('Profession:15'), value: 'AUTONOMO PROFESIONAL - FARMACEUT' },
  { label: i18next.t('Profession:16'), value: 'AUTONOMOS JUBILADOS - RETIRADOS' },
  { label: i18next.t('Profession:17'), value: 'AUTONOMO PROFESIONAL - BIOQUIM' },
  { label: i18next.t('Profession:18'), value: 'AUTONOMOS PROFESIONAL - FONOUD' },
  { label: i18next.t('Profession:19'), value: 'AUTONOMO PROFESIONAL - KINESIO' },
  { label: i18next.t('Profession:20'), value: 'AUTONOMO PROFESIONAL - PSICOLO' },
  { label: i18next.t('Profession:21'), value: 'AUTONOMO PROFESIONAL - RADIOLO' },
  { label: i18next.t('Profession:22'), value: 'AUTONOMOS ACTIVIDADES VARIAS' },
  { label: i18next.t('Profession:23'), value: 'AUTONOMOS AMAS DE CASA' },
  { label: i18next.t('Profession:24'), value: 'APRENDICES ENTRE 14 Y 25 AÑOS' },
  { label: i18next.t('Profession:25'), value: 'ESTUDIANTES' },
  { label: i18next.t('Profession:26'), value: 'ADMINISTRATIVOS' },
  { label: i18next.t('Profession:27'), value: 'ESCRIBANO' },
  { label: i18next.t('Profession:28'), value: 'PROVEEDOR OSDE' },
  { label: i18next.t('Profession:29'), value: 'PRESTADOR OBLIGATORIO OSDE' },
  { label: i18next.t('Profession:30'), value: 'DESCTO. OSDE PROFESIONAL MEDICO' },
  { label: i18next.t('Profession:31'), value: 'OTROS' }
].sort(sortByLabel);

export const TAX_CONDITION = [
  { label: i18next.t('TaxCondition:final'), value: 'IVA Consumidor Final' },
  { label: i18next.t('TaxCondition:exent'), value: 'IVA Exento' },
  { label: i18next.t('TaxCondition:responsable'), value: 'IVA Responsable Inscripto' }
];

export const RETIREMENT_INSURANCE = [
  { label: i18next.t('RetirementInsurance:ACO'), value: 'Aconcagua' },
  { label: i18next.t('RetirementInsurance:AGR'), value: 'Federación Agraria' },
  { label: i18next.t('RetirementInsurance:ANT'), value: 'Anticipar' },
  { label: i18next.t('RetirementInsurance:ASE'), value: 'Aseguradora Solidarios' },
  { label: i18next.t('RetirementInsurance:BIN'), value: 'Binaria' },
  { label: i18next.t('RetirementInsurance:BSA'), value: 'Buenos Aires N y L' },
  { label: i18next.t('RetirementInsurance:CON'), value: 'Consolidar' },
  { label: i18next.t('RetirementInsurance:CRI'), value: 'San Cristóbal' },
  { label: i18next.t('RetirementInsurance:ENT'), value: 'Inst. Entre Rios' },
  { label: i18next.t('RetirementInsurance:EST'), value: 'Estrella' },
  { label: i18next.t('RetirementInsurance:FED'), value: 'Federación Patronal' },
  { label: i18next.t('RetirementInsurance:FRA'), value: 'Fraterna' },
  { label: i18next.t('RetirementInsurance:GEN'), value: 'Generar' },
  { label: i18next.t('RetirementInsurance:GES'), value: 'Génesis' },
  { label: i18next.t('RetirementInsurance:HOL'), value: 'Holando Argentina' },
  { label: i18next.t('RetirementInsurance:ITT'), value: 'ITT Harford' },
  { label: i18next.t('RetirementInsurance:MAÑ'), value: 'Mañana' },
  { label: i18next.t('RetirementInsurance:MET'), value: 'Metropolitan Life' },
  { label: i18next.t('RetirementInsurance:NAC'), value: 'Nación' },
  { label: i18next.t('RetirementInsurance:OME'), value: 'Omega' },
  { label: i18next.t('RetirementInsurance:POL'), value: 'Metropol' },
  { label: i18next.t('RetirementInsurance:PRE'), value: 'Previnter' },
  { label: i18next.t('RetirementInsurance:PRO'), value: 'Profuturo' },
  { label: i18next.t('RetirementInsurance:PRV'), value: 'Previsión' },
  { label: i18next.t('RetirementInsurance:PRY'), value: 'Proyección' },
  { label: i18next.t('RetirementInsurance:SAN'), value: 'Sancor' },
  { label: i18next.t('RetirementInsurance:SEG'), value: 'Segunda' },
  { label: i18next.t('RetirementInsurance:SEN'), value: 'Senium' },
  { label: i18next.t('RetirementInsurance:SIE'), value: 'Siembra' },
  { label: i18next.t('RetirementInsurance:UAP'), value: 'U.A.P.' },
  { label: i18next.t('RetirementInsurance:ZUR'), value: 'Zurich Iguazu' }
].sort(sortByLabel);

export const PROVINCE = [
  { label: 'Ciudad Autónoma de Buenos Aires', value: 'Ciudad Autónoma de Buenos Aires' },
  { label: i18next.t('Province:2'), value: 'Buenos Aires' },
  { label: i18next.t('Province:3'), value: 'Catamarca' },
  { label: i18next.t('Province:4'), value: 'Córdoba' },
  { label: i18next.t('Province:5'), value: 'Corrientes' },
  { label: i18next.t('Province:6'), value: 'Entre Ríos' },
  { label: i18next.t('Province:7'), value: 'Jujuy' },
  { label: i18next.t('Province:8'), value: 'Mendoza' },
  { label: i18next.t('Province:9'), value: 'La Rioja' },
  { label: i18next.t('Province:10'), value: 'Salta' },
  { label: i18next.t('Province:11'), value: 'San Juan' },
  { label: i18next.t('Province:12'), value: 'San Luis' },
  { label: i18next.t('Province:13'), value: 'Santa Fe' },
  { label: i18next.t('Province:14'), value: 'Santiago del Estero' },
  { label: i18next.t('Province:16'), value: 'Tucumán' },
  { label: i18next.t('Province:17'), value: 'Chaco' },
  { label: i18next.t('Province:18'), value: 'Chubut' },
  { label: i18next.t('Province:19'), value: 'Formosa' },
  { label: i18next.t('Province:20'), value: 'Misiones' },
  { label: i18next.t('Province:21'), value: 'Neuquén' },
  { label: i18next.t('Province:22'), value: 'La Pampa' },
  { label: i18next.t('Province:23'), value: 'Río Negro' },
  { label: i18next.t('Province:24'), value: 'Santa Cruz' },
  { label: i18next.t('Province:25'), value: 'Tierra de Fuego' }
].sort(sortByLabel);
