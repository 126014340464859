import i18next from 'i18next';

i18next.addResources('es', 'RefundAccreditation', {
  cbu: 'Número de CBU',
  cbuPlaceholder: 'Ingresá tu número de CBU',
  ownAccount: '¿Se trata de una cuenta propia?',
  ownerName: 'Nombre/s del titular',
  ownerNamePlaceholder: 'Ingresá nombre del titular',
  ownerSurname: 'Apellido/s del titular',
  ownerSurnamePlaceholder: 'Ingresá apellido del titular',
  ownerCuil: 'CUIL del titular',
  ownerCuilPlaceholder: '00-00000000-0',
  accountNumber: 'Número de cuenta',
  accountNumberPlaceholder: 'Ingresá el número de cuenta'
});
