export const InitialState = {
  individuo: null
};

export interface IndividuoState {
  individuo: any;
}

enum ActionTypes {
  SAVE_INDIVIDUO = 'SAVE_INDIVIDUO'
}

interface SaveIndividuo {
  type: ActionTypes.SAVE_INDIVIDUO;
  payload: IndividuoState;
}

export const actionCreator = {
  saveIndividuo: (individuo: IndividuoState) => ({
    type: ActionTypes.SAVE_INDIVIDUO,
    payload: individuo
  })
};

export type Action = SaveIndividuo;

export const reducer = (state: IndividuoState = InitialState, action: Action): IndividuoState => {
  switch (action.type) {
    case ActionTypes.SAVE_INDIVIDUO:
      return {
        ...state,
        individuo: action.payload
      };
    default:
      return state;
  }
};
