/*
 * TODO Add this if you need it
 * export const sidebarSize = 100;
 * export const topbarSize = 60;
 */

export const BASE_WIDTH = 980;

// INPUTS
export enum InputSizes {
  MD = 'medium',
  LG = 'large'
}

export const SCREEN_SIZES: { [key: string]: { name: string; width: number } } = {
  xxs: { name: 'xxs', width: 320 },
  xs: { name: 'xs', width: 375 },
  sm: { name: 'sm', width: 550 },
  md: { name: 'md', width: 768 },
  lg: { name: 'lg', width: 1024 }
};
