import i18next from 'i18next';

i18next.addResources('es', 'SwornDeclarationSummary', {
  sectionTitle: 'Solicitud de afiliación',
  sectionTitleDDJJ: 'Solicitud de información',
  sectionSubtitle: 'Declaración Jurada de Salud',
  sectionSubtitleDDJJ: 'Estado de salud actual',
  subtitleOTHERS: 'Solicitud de trámite',
  checkHolderInfo:
    '{{currentName}}, por favor, verificá que toda la información que completaste sea correcta.',
  checkFamilyInfo:
    'Por favor, verificá que toda la información que completaste de {{currentName}} sea correcta.',
  rememberDeclaration: 'Si fuera necesario, podés hacer las modificaciones correspondientes.',
  confirmButton: 'Sí, la información es correcta',
  heightAndWeight: 'Altura y peso',
  height: 'Altura:',
  weight: 'Peso:',
  gender: 'Sexo',
  pregnant: 'Embarazo:',
  lastPeriod: 'Ultima menstruación:',
  analisis: 'Estudios',
  lastYearAnalisis: 'Estudios en el último año:',
  results: 'Resultados normales:',
  diagnostic: 'Diagnóstico:',
  hospitalization: 'Internaciones',
  howMuch: '¿Cuántas?',
  year: 'Año/s:',
  surgery: 'Cirugías',
  type: 'Tipo:',
  intervention: 'Intervención:',
  prosthesis: 'Prótesis:',
  previousPathologies: 'Patologías',
  pathology: 'Patología:',
  graduation: 'Graduación:',
  additions: 'Adicciones',
  treatments: 'Tratamientos',
  actualTreatments: 'Tratamientos en curso:',
  nextTreatments: 'Próximos tratamientos:',
  disabilityCertificate: 'Certificado por discapacidad',
  otherPathologies: 'Otras patologías',
  whichOtherVision: 'Otras - ¿Cuál/es?:',
  reason: 'Motivo:',
  notApplicable: 'No aplica',
  disabilityDateEmision: 'Fecha de emision',
  disabilityDateExpire: 'Fecha de vencimiento',
  disabilityDiagnostic: 'Diagnóstico'
});
