import i18next from 'i18next';
import { HAD_SOME_TREATMENT_TYPES } from '~components/SwornDeclaration/constants';

i18next.addResources('es', 'TreatmentTypes', {
  [HAD_SOME_TREATMENT_TYPES.NEUROLINGUISTIC]: 'Neurolingüística',
  [HAD_SOME_TREATMENT_TYPES.PSYCHOMOTRICITY]: 'Psicomotricidad',
  [HAD_SOME_TREATMENT_TYPES.PSYCHOPEDAGOGY]: 'Psicopedagogía',
  [HAD_SOME_TREATMENT_TYPES.THERAPHY]: 'Terapia ocupacional',
  [HAD_SOME_TREATMENT_TYPES.STIMULATION]: 'Estimulación temprana',
  [HAD_SOME_TREATMENT_TYPES.NONE]: 'Ninguna de los anteriores',
  [`which${HAD_SOME_TREATMENT_TYPES.NEUROLINGUISTIC}`]: 'neurolingüístico',
  [`which${HAD_SOME_TREATMENT_TYPES.PSYCHOMOTRICITY}`]: 'psicomotriz',
  [`which${HAD_SOME_TREATMENT_TYPES.PSYCHOPEDAGOGY}`]: 'psicopedagógico',
  [`which${HAD_SOME_TREATMENT_TYPES.THERAPHY}`]: 'terapéutico',
  [`which${HAD_SOME_TREATMENT_TYPES.STIMULATION}`]: 'de estimulación'
});
