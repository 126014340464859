import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { scrollToTopRef } from '~utils/events';

import GeneralFooter from './components/GeneralFooter';
import GeneralHeader from './components/GeneralHeader';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  withGeneralContainer?: boolean;
}

function WithGeneralContainer({ children, withGeneralContainer }: Props) {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      scrollToTopRef(null);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return withGeneralContainer ? (
    <>
      <GeneralHeader />
      <div className={`full-width ${styles.generalContainer}`}>{children}</div>
      <GeneralFooter />
    </>
  ) : (
    <>{children}</>
  );
}
export default WithGeneralContainer;
