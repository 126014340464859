import i18next from 'i18next';

i18next.addResources('es', 'JobDataForm', {
  job: 'Trabajo #{{jobNumber}}',
  companyName: 'Nombre de la empresa',
  companyNamePlaceholder: 'Ingrese nombre de la empresa',
  cuit: 'CUIT',
  cuitPlaceholder: 'XX-XXXXXXXX-X',
  grossSalary: '¿Cuál es tu sueldo bruto?',
  grossSalaryPlaceholder: 'Ingrese sueldo bruto',
  addAnotherJob: '¿Querés asociar otro trabajo?',
  addJob: 'Agregar trabajo',
  removeJob: 'Remover este trabajo'
});
