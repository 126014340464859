import { useState, useEffect, useCallback } from 'react';
import { getSize } from '~utils/sizes';

export const useSize = (setter: (size: string) => void) => {
  const [windowSize, setWindowSize] = useState<string>(getSize(window.innerWidth));

  const handleResize = useCallback(() => {
    const size = getSize(innerWidth);
    if (windowSize !== size) {
      setWindowSize(size);
      setter(size);
    }
  }, [setter, windowSize]);

  useEffect(() => {
    setter(getSize(window.innerWidth));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, setter]);

  return windowSize;
};
