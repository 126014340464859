import { F1_FIELDS, JOB_DATA_FIELDS } from '~constants/fields';
import { generateFieldArrayKey } from '~utils/form';
import { GenericObjectInterface } from '~utils/types';

export const DEFAULT_COUNTRY = { formatted: 'Argentina', value: 'Argentina' };

export const JOBS_INITIAL_VALUE = [
  {
    [JOB_DATA_FIELDS.companyName]: '',
    [JOB_DATA_FIELDS.cuit]: '',
    [JOB_DATA_FIELDS.grossSalary]: '',
    key: generateFieldArrayKey(F1_FIELDS.JOB_DATA.jobs)
  }
];

export const CUIL_LINK = 'https://www.anses.gob.ar/consulta/constancia-de-cuil';

export const AFIP_LINK = 'https://serviciosweb.afip.gob.ar/genericos/nomencladorActividades/index.aspx';

export const CBU_LINK = 'https://serviciosweb.afip.gob.ar/genericos/nomencladorActividades/index.aspx';

export const CONDITION_IVA = [
  { label: 'Responsable inscripto', value: 'Responsable inscripto' },
  { label: 'Exento', value: 'Exento' }
];

export const AUTO_FOCUS_DELAY = 500;

export const WIZARD_SECTIONS = {
  f1: 'AffiliationForm',
  swornDeclaration: 'SwornDeclaration',
  other: 'AffiliationFormOther'
};

export const ERROR_QUERY_PARAMS: GenericObjectInterface<string> = {
  code: 'code',
  fault: 'fault',
  e404: '?code=E-404',
  e2000: '?code=E-2000'
};

export const MAX_LENGTH = 139;

export const DNI_OF_CUIL_LENGTH = 8;
export const DNI_OF_CUIL = 2;

export const APIGEE_ERRORS: GenericObjectInterface<string> = {
  invalidToken: 'steps.jwt.InvalidToken',
  expiredToken: 'steps.jwt.TokenExpired'
};

export const WHO_OPTIONS = {
  HOLDER: 'holder',
  COUPLE: 'couple',
  SON_UNDER_GUARD: 'son_under_guard',
  SONS: 'sons'
};

export const SURGERY_TYPES = {
  CARDIOLOGY: 'cardiology',
  ABDOMINAL: 'abdominal',
  TRAUMATOLOGY: 'traumatology',
  AESTHETIC: 'aesthetic',
  GINECOLOGY: 'ginecology',
  NERVOUS: 'nervous',
  ENDOCRINE: 'endocrine',
  OTHER: 'surgeryOther'
};
