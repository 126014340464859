import i18next from 'i18next';

i18next.addResources('es', 'SwornStatementHome', {
  thanks: `¡Gracias, {{- name}}!
Solo queda un paso más`,
  thanksSecond: '¡Muchas gracias, {{- name}}!',
  next: 'Solo resta confirmar los términos y condiciones para terminar.',
  terms: 'Ver condiciones',
  family: 'Familiar {{number}}',
  singleComplete: 'Completaste tu Declaración Jurada.',
  singleCompleteBeneficiary: 'Ya cargaste tu documentación.',
  allCompleted: 'Ya completaste toda tu información y la de tu grupo familiar.',
  member:
    'Nos indicaste que {{- name}} pertenece a tu grupo familiar. Por favor, completá su Declaración Jurada para continuar con el proceso de asociación online.',
  memberPreDdjj:
    'Nos indicaste que {{- name}} pertenece a tu grupo familiar. Por favor, completá su Declaración Jurada para continuar con la carga de información de salud.',
  instructions:
    'A continuación vas a completar tu Declaración Jurada de Salud. Contestá todas las preguntas para finalizar tu solicitud de asociación online.',
  instructionsBeneficiary: `Para continuar con tu solicitud, es necesario que adjuntes tu documentación.

Luego completá la Declaración Jurada de Salud de los integrantes de tu grupo familiar.
Por favor, contestá todas las preguntas para finalizar el proceso de afiliación online.`,
  instructionsPreDDJJ:
    'A continuación te vamos a solicitar información sobre tu condición actual de salud para que sea procesado por OSDE.',
  disclaimerFirst: `Declaro bajo juramento que en la presente informé la totalidad de mis antecedentes de salud y/o de cada uno de los integrantes de mi grupo familiar, no habiendo omitido dato alguno, estando por lo tanto OSDE facultado para resolver el vínculo en caso de falsedad en los términos del Dec. Reg. 1993/11, art. 9, inc. b), el que también declaro conocer.

Autorizo expresamente a OSDE a requerir información médica referida a mi persona y/o grupo familiar a cualquier prestador y/o institución de salud.

`
});
