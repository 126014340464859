import 'react-devtools';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import '~config';

import App from './app';
import './scss/application.scss';

// eslint-disable-next-line init-declarations
declare let module: NodeModule & { hot: any };

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById('root')
  );
};

fetch(`${process.env.PUBLIC_URL}/config.json`)
  .then(res => res.json())
  .then(config => {
    window.config = config;
    // Render once
    render();
  })
  .catch(error => {
    console.error(error);
  });

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./app', () => {
    render();
  });
}
