import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import i18next from 'i18next';
import StepHeader from '~components/StepHeader';
import { getMarkdown } from '~utils/markdown';

import { MARKDOWN_TEXTS } from '../constants';
import { renderers } from '../renderers';
import SectionParagraph from '../components/SectionParagraph';
import { parseHtml } from '../utils';
import styles from '../styles.module.scss';

function CorporativoF2() {
  const [markdown, setMarkdown] = useState<string>('');

  useEffect(() => {
    getMarkdown(MARKDOWN_TEXTS.f2Corporativo).then(md => setMarkdown(md));
  }, []);

  return (
    <div className={`full-width column center ${styles.container}`}>
      <StepHeader
        title={i18next.t('General:termsAndConditions')}
        subtitle={i18next.t('General:terms')}
        withSeparator
        className={styles.headerContainer}
      />
      <SectionParagraph>{i18next.t('Terms:disclaimer')}</SectionParagraph>
      {markdown && (
        <Markdown source={markdown!} renderers={renderers} escapeHtml={false} astPlugins={[parseHtml]} />
      )}
    </div>
  );
}

export default CorporativoF2;
