import i18next from 'i18next';

import { PATHOLOGY_TYPES, MYOPIA_TYPES, VISION_TYPES } from './constants';

i18next.addResources('es', 'PathologiesOptions', {
  [PATHOLOGY_TYPES.HEPATITIS_A]: 'Hepatitis A',
  [PATHOLOGY_TYPES.HEPATITIS_B]: 'Hepatitis B',
  [PATHOLOGY_TYPES.HEPATITIS_C]: 'Hepatitis C',
  [PATHOLOGY_TYPES.DIABETES1]: 'Diabetes tipo I',
  [PATHOLOGY_TYPES.DIABETES2]: 'Diabetes tipo II',
  [PATHOLOGY_TYPES.HIV]: 'HIV',
  [PATHOLOGY_TYPES.HEREDITARY]: 'Enfermedades congénitas y/o hereditarias',
  [PATHOLOGY_TYPES.PSYCHIATRIC]: 'Enfermedades psiquiátricas',
  [PATHOLOGY_TYPES.ASMA]: 'Asma',
  [PATHOLOGY_TYPES.ASMA_SEVERE]: 'Asma severo',
  [PATHOLOGY_TYPES.EATING_DISORDERS]: 'Trastornos de la conducta alimentaria (Bulimia, Anorexia)',
  [PATHOLOGY_TYPES.HYPOTHYROIDISM]: 'Hiper o hipotiroidismo',
  [PATHOLOGY_TYPES.DYSLIPEMIA]: 'Dislipemia (niveles de colesterol y/o triglicéridos elevados)',
  [PATHOLOGY_TYPES.EPILEPSY]: 'Epilepsia no refractaria',
  [PATHOLOGY_TYPES.EPILEPSY_REFRACTORY]: 'Epilepsia refractaria',
  [PATHOLOGY_TYPES.CARDIAC]: 'Enfermedades cardíacas',
  [PATHOLOGY_TYPES.HIPERTENSION]: 'Hipertensión arterial',
  [PATHOLOGY_TYPES.HPV]: 'HPV',
  [PATHOLOGY_TYPES.AUDITION]: 'Dificultades en la audición',
  [PATHOLOGY_TYPES.VISION]: 'Dificultades en la visión',
  [PATHOLOGY_TYPES.NONE]: 'Ninguna de las anteriores',
  [VISION_TYPES.MYOPIA]: 'Miopía',
  [VISION_TYPES.ASTIGMATISM]: 'Astigmatismo',
  [VISION_TYPES.HYPEROPIA]: 'Hipermetropía',
  [VISION_TYPES.OTHER]: 'Otras',
  [MYOPIA_TYPES.LESS]: 'Menor a -6 dioptrías',
  [MYOPIA_TYPES.GREATER]: 'Igual o mayor a -6 dioptrías',
  [`which${PATHOLOGY_TYPES.HEREDITARY}`]: 'enfermedades congénitas y/o hereditarias',
  [`which${PATHOLOGY_TYPES.PSYCHIATRIC}`]: 'enfermedades psiquiátricas',
  [`which${PATHOLOGY_TYPES.AUDITION}`]: 'dificultades en la audición'
});
