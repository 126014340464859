import i18next from 'i18next';

i18next.addResources('es', 'Home', {
  title: 'Formulario para asociarte online',
  instructions:
    'Completá con tus datos el formulario de alta online para comenzar con tu solicitud de afiliación.'
});
i18next.addResources('es', 'HomeDDJJ', {
  title: 'Formulario para completar tus datos',
  instructions: 'Completá con tus datos personales el siguiente formulario.'
});
i18next.addResources('es', 'HomeB', {
  title: 'Formulario para asociarte online',
  instructions:
    'Completá con tus datos el formulario de alta online para comenzar con tu solicitud de alta de beneficiario.'
});
i18next.addResources('es', 'HomeOTHERS', {
  title: 'Formulario para completar tus datos',
  instructions: 'Completá con tus datos personales el siguiente trámite: {{-tramite}}.'
});
i18next.addResources('es', 'HomeF1', {
  title: 'Formulario Online',
  instructions: 'Completá con tus datos el formulario para continuar con tu solicitud de modificación.'
});
