import i18next from 'i18next';

i18next.addResources('es', 'FinishAffiliationProcess', {
  thanks: '¡Muchas gracias!',
  thanksDDJJ: '¡Muchas gracias, {{nombre}} {{apellido}}!',
  thanksOTHERS: '¡Muchas gracias, {{nombre}} {{apellido}}!',
  message: `Muchas gracias por haber completado tus datos.

En breve, nos comunicaremos con vos para confirmarte el estado de tu solicitud.`,
  messageF1: `Muchas gracias por haber completado tus datos.

Estamos procesando tu solicitud, en caso de que necesitemos información adicional, nos vamos a comunicar con vos. En breve te enviaremos un mail con la copia de lo que completaste. Este proceso puede demorar 72 horas.`,
  messagePotentialRisk: `¡Muchas gracias por haber completado tus datos!

En caso de ser necesario presentar información adicional sobre los datos cargados en tu declaración jurada de salud y la de tu grupo familiar, te estaremos contactando para indicarte cómo proseguir.`,
  messageApplicationTypeDDJJ: 'Completaste todo el proceso de carga de datos personales.',
  messageApplicationTypeOTHERS: 'Completaste todo el proceso de carga de datos personales.'
});
