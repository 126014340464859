import i18next from 'i18next';

i18next.addResources('es', 'Questions', {
  service: 'Alta online',
  title: 'Solicitud de información adicional',
  instructions: 'El asesor comercial te solicita la siguiente información:',
  thanks: '¡Muchas gracias!',
  message: `Muchas gracias por haber completado tus datos.
  
En breve, nos comunicaremos con vos para confirmarte el estado de tu solicitud.`,
  history: 'Historial'
});
