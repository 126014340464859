export default {
  default: '/',
  login: '/login',
  recoverPassword: '/recover_password',
  form: '/form',
  declaration: '/declaration',
  request: '/solicitud',
  f1: '/f1',
  f2: '/f2',
  others: '/others',
  summary: '/resumen',
  selfManagementsummary: '/resumen-autogestion',
  incorrectInformation: '/informacion-incorrecta',
  finish: '/finalizar',
  thanks: '/gracias',
  missing: '/error',
  sworn: '/declaracion_jurada',
  holder: '/titular',
  couple: '/pareja',
  son: '/hijo',
  member: '/miembro_familiar',
  terms: '/condiciones_generales',
  termsDDJJ: '/condiciones_generales_ddjj',
  termsCorporative: '/condiciones_generales_co',
  terms8430: '/condiciones_generales_co_8430',
  terms8260: '/condiciones_generales_co_8260',
  termsF2: '/condiciones_generales_f2',
  termsF2Corporativo: '/condiciones_generales_f2_corporativo',
  termsOthers: '/condiciones_generales_otros',
  termsOthers6030: '/condiciones_generales_otros_6030',
  validate: '/validar',
  validateON: '/on/validar',
  notRedirect: '#',
  questions: '/questions',
  beneficiaryCancel: '/beneficiary_cancel',
  binario6030: '/f1/6030'
};
