import { RefType } from './types';

export const onBeforeUnload = (validate: boolean, e: Event) => {
  if (validate) {
    e.preventDefault();
    e.returnValue = true;
  }
};

// Trick for offset for absolute elements
const getOffsetTop = (element: any) => {
  let offsetTop = 0;
  let el = element;
  while (el) {
    offsetTop += el.offsetTop;
    el = el.offsetParent;
  }
  return offsetTop;
};

export const scrollToTopRef = (el: RefType, withSmoothBehavior = true) => {
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
  const top = el ? getOffsetTop(el) : 0;
  if (withSmoothBehavior && supportsNativeSmoothScroll) {
    setTimeout(() => window.scrollTo({ top, behavior: 'smooth' }), 0);
  } else {
    window.scrollTo(0, top);
  }
};
