import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

interface Props {
  formError: string;
}

function StepError({ formError }: Props) {
  return (
    <div className={`row center ${styles.container}`}>
      <ExclamationCircleOutlined className={styles.icon} />
      <span className={`text-light-normal text-color-primary ${styles.error}`}>{formError}</span>
    </div>
  );
}

export default StepError;
