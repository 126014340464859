import i18next from 'i18next';

i18next.addResources('es', 'PersonalDataForm', {
  name: 'Nombre/s',
  namePlaceholder: 'Ingresá tu nombre completo',
  surname: 'Apellido/s',
  surnamePlaceholder: 'Ingresá tu apellido',
  birthDate: 'Fecha de nacimiento',
  birthDatePlaceholder: '01/01/2020',
  relationship: '¿Cuál es tu relación con esta persona?',
  parent: 'Soy su padre/madre',
  stepParent: 'Hijo/a de mi pareja',
  legalGuardianship: 'Tengo su tutoría legal',
  gender: 'Sexo',
  male: 'Masculino',
  female: 'Femenino',
  nonBinary: 'No Binario',
  nationality: 'Nacionalidad',
  nationalityPlaceholder: 'Ingresá tu nacionalidad',
  cuil: 'CUIL',
  cuilPlaceholder: '00-00000000-0',
  documentType: 'Tipo de documento',
  documentTypePlaceholder: 'Seleccioná un tipo',
  documentNumber: 'Número de documento',
  documentNumberPlaceholder: 'Ingresá tu número de documento',
  email: 'E-mail',
  emailPlaceholder: 'Ingresá tu dirección de e-mail',
  hasCuil: '¿Tenés CUIL?',
  knowCuil: '¿Cómo saber cuál es mi CUIL?'
});

i18next.addResources('es', 'FamilyDataForm', {
  namePlaceholder: 'Ingresá su nombre completo',
  surnamePlaceholder: 'Ingresá su apellido',
  nationalityPlaceholder: 'Ingresá su nacionalidad',
  documentNumberPlaceholder: 'Ingresá su número de documento',
  emailPlaceholder: 'Ingresá su dirección de e-mail',
  hasCuil: '¿Tiene CUIL?',
  knowCuil: '¿Cómo saber cuál es su CUIL?'
});
