import i18next from 'i18next';

i18next.addResources('es', 'SummaryValidation', {
  summaryTitle: 'Formulario de alta online',
  summaryTitleDDJJ: 'Formulario de datos',
  summaryFirstDescription:
    '{{- currentName}}, por favor, verificá que toda la información que completaste sea correcta.',
  summarySecondDescription: 'Si fuera necesario, podés hacer las modificaciones correspondientes.',
  buttonConfirm: 'Sí, la información es correcta',
  buttonReject: 'Información incorrecta',
  plan: 'Plan OSDE:',
  notAnswered: 'No contestado',
  yes: 'Sí',
  no: 'No',
  couple: 'Pareja',
  sons: 'Hijo/a',
  name: 'Nombre/s:',
  surname: 'Apellido/s:',
  birthDate: 'Fecha de nacimiento:',
  gender: 'Sexo:',
  nationality: 'Nacionalidad:',
  cuil: 'CUIL:',
  documentType: 'Tipo de documento:',
  documentNumber: 'Número:',
  email: 'E-mail:',
  maritalStatus: 'Estado civil:',
  profession: 'Profesión:',
  taxCondition: 'Condición frente al IVA:',
  healthCoverage: 'Cobertura de salud:',
  hasLifeInsurance: 'Seguro de vida:',
  hasRetirementInsurance: 'Seguro de retiro:',
  benefits: 'Intereses:',
  street: 'Calle:',
  streetNumber: 'Número:',
  floor: 'Piso:',
  apartment: 'Apartamento',
  additional: 'Información adicional:',
  district: 'Localidad:',
  country: 'País:',
  province: 'Provincia:',
  department: 'Departamento:',
  zipCode: 'Código postal:',
  contactNumberPrimary: 'Teléfono fijo:',
  contactNumberSecondary: 'Teléfono celular:',
  jobs: 'Tus trabajos',
  companyName: 'Nombre de la empresa:',
  cuit: 'CUIT:',
  grossSalary: 'Sueldo bruto:',
  payment: 'Pago:',
  cbu: 'Número de CBU:',
  cbuRA: 'Número de CBU:',
  accountNumber: 'Número de cuenta:',
  ownAccount: 'Cuenta propia:',
  which: 'Cuál:',
  notAdded: '(No agregado)',
  paymentDataStep: 'Datos para el débito automático',
  otherBenefits: 'Otros intereses:',
  ownerName: 'Nombre/s titular:',
  ownerSurname: 'Apellido/s titular:',
  ownerCuil: 'CUIL titular:',
  paymentWithOutLabel: 'Débito automático:',
  paymentWithOutValue: 'No modificar',
  healtCare: '¿Cuál es su cobertura de salud actual?',
  observations: 'Observaciones',
  yourProcedures: 'Tus trámites'
});

i18next.addResources('es', 'DocumentType', {
  dni: 'DNI',
  passport: 'Pasaporte',
  dniShort: 'DNI',
  cuil: 'CUIL',
  cuit: 'CUIT'
});
