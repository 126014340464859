import React from 'react';
import i18next from 'i18next';
import { Progress as ProgressBar } from 'antd';
import cn from 'classnames';
import { MALIBU } from '~constants/colors';

import 'antd/es/progress/style/css';
import styles from './styles.module.scss';

interface Props {
  percent: number;
  showInfo?: boolean;
  className?: string;
  disabled?: boolean;
}

function Progress({ percent, className = '', showInfo = false, disabled }: Props) {
  return (
    <div className={`${styles.container} ${className}`}>
      <p
        className={cn('text-light-normal text-small', styles.percentageDescription, {
          [styles.disabled]: disabled
        })}
      >
        {i18next.t('ProgressBar:completed', { completionPercentage: percent })}
      </p>
      <ProgressBar percent={percent} showInfo={showInfo} strokeColor={MALIBU} />
    </div>
  );
}
export default Progress;
