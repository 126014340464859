import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Progress from '~components/Progress';
import { usePrevious } from '~app/hooks/usePrevious';
import i18next from 'i18next';

import StepError from './components/StepError';
import styles from './styles.module.scss';
import StepSuccess from './components/StepSuccess';

interface Props {
  className?: string;
  formError?: string;
  percent?: number;
  smallerSubtitle?: boolean;
  subtitle: string;
  title: string;
  withProgress?: boolean;
  withSeparator?: boolean;
  contentClassName?: string;
  progressClassName?: string;
}

function StepHeader({
  className = '',
  formError,
  percent,
  smallerSubtitle,
  subtitle,
  title,
  withSeparator,
  withProgress,
  contentClassName = '',
  progressClassName = ''
}: Props) {
  const [showMessageSaved, setMessageSaved] = useState(false);
  const milisecondsToShowMessageSaved = 4500;
  const prevPercent = usePrevious(percent) || 1;

  useEffect(() => {
    if (percent && prevPercent && percent > prevPercent) {
      setMessageSaved(true);
      setTimeout(() => setMessageSaved(false), milisecondsToShowMessageSaved);
    }
    if (percent && prevPercent && prevPercent > percent) {
      setMessageSaved(false);
    }
  }, [percent, prevPercent]);

  return (
    <div
      className={cn('full-width row center', styles.headerContainer, className, {
        'row border-separator': withSeparator,
        [styles.formError]: !!formError
      })}
    >
      <div className={`full-width max-width-container ${styles.content} ${contentClassName}`}>
        <div className={`row space-between m-bottom-7 ${styles.titleContainer}`}>
          <h1 className={`text-light-normal text-color-gray ${styles.title}`}>{title}</h1>
          {showMessageSaved && (
            <StepSuccess message={i18next.t('StepForward:messageAutoSaved')} icon="info" />
          )}
          {!!formError && <StepError formError={formError} />}
        </div>
        <div className={`row ${styles.subtitleContainer}`}>
          <h2
            className={cn('text-medium-normal', styles.subtitle, {
              [styles.smallerSubtitle]: smallerSubtitle
            })}
          >
            {subtitle}
          </h2>
          {withProgress && <Progress className={progressClassName} percent={percent!} />}
        </div>
      </div>
    </div>
  );
}

export default StepHeader;
