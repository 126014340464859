import React from 'react';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';
import WithGeneralContainer from '~components/WithGeneralContainer';

interface Props extends RouteProps {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  redirectTo?: string;
  withGeneralContainer?: boolean;
}

function RouteItem({ redirectTo, withGeneralContainer, ...config }: Props) {
  return redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <WithGeneralContainer withGeneralContainer={withGeneralContainer}>
      <Route {...config} />
    </WithGeneralContainer>
  );
}

export default RouteItem;
