import { FormikProps, FormikValues } from 'formik';
import shallowEqual from 'shallowequal';
import { GenericObjectInterface } from '~utils/types';

const clearValue = (value?: string | number, separator = ' ') =>
  `${value || ''}`
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .replace(separator, '');

export const specificFormat = (groups: Array<{ starts: number; ends: number }>, separator = ' ') => (
  val?: string | number
) => {
  const value = clearValue(val, separator);
  const parts = groups.reduce((acum: string[], { starts, ends }: { starts: number; ends: number }) => {
    const subStr = value.substring(starts, ends);
    if (subStr) {
      acum.push(subStr);
    }
    return acum;
  }, []);

  if (parts.length) {
    return parts.join(separator);
  }
  return value;
};

export const specificNormalize = ({ length }: { length: number }, separator = ' ') => (
  val?: string | number
) => clearValue(val, separator).substr(0, length);

export const dotFormatter = ({ max }: { max: number }, separator = '.') => (value?: string) =>
  `${clearValue(value || '')}`.substr(0, max).replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const dotParser = ({ max }: { max: number }, separator = '.') => (value?: string) =>
  `${clearValue(value, separator)}`.substr(0, max).replace(new RegExp(`/(${separator},*)/g`), '');

export const getError = (name: string, form?: FormikValues) => {
  if (!name) {
    return;
  }
  const split = name.split('.');
  if (split.length > 1) {
    return form?.errors?.[split[0]]?.[split[1]]?.[split[2]];
  }

  return form?.errors[name];
};

export const getTouched = (name: string, form?: FormikValues) => {
  const split = name.split('.');
  if (split.length > 1) {
    return form?.touched?.[split[0]]?.[split[1]]?.[split[2]];
  }

  return form?.touched[name] || false;
};

export const getFieldName = (name: string, containerName?: string, index?: number) =>
  containerName && typeof index === 'number' ? `${containerName}.${index}.${name}` : name;

export const getFieldValue = (
  formValues: FormikValues,
  fieldName: string,
  containerName?: string,
  index?: number
) => {
  let fieldValue = null;

  if (containerName && index !== undefined && !isNaN(index)) {
    fieldValue = formValues[containerName][index][fieldName];
  } else {
    fieldValue = formValues[fieldName];
  }

  return fieldValue;
};

export const generateKey = (name: string, index: number) => `${name}-${index}-${new Date().getTime()}`;

export const generateFieldArrayKey = (name: string) => `${name}-${new Date().getTime()}`;

export const removeExcludedValues = (valuesSelected: Array<string>, exclude: Array<string>) =>
  valuesSelected &&
  valuesSelected.reduce(
    (acc: Array<string>, value: string) => (exclude.includes(value) ? acc : [...acc, value]),
    []
  );

export const getInitialValues = (
  fieldNames: object,
  values: object,
  initialValues?: GenericObjectInterface<any>
) => {
  const fieldsKeys = Object.values(fieldNames).reduce((accum, keys) => ({ ...accum, ...keys }), {});
  const formValues: GenericObjectInterface<any> = Object.values(values).reduce(
    (acum, val) => ({ ...acum, ...val }),
    {}
  );

  return Object.values(fieldsKeys).reduce(
    (accum, key) => ({
      ...(accum as {}),
      [key as string]: formValues[key as string] || initialValues?.[key as string] || ''
    }),
    {}
  );
};

type FieldExtractorCallback<T> = (value: T) => any;

export type UpdateRemoteFormFieldDeff<T> = {
  fieldExtractor: FieldExtractorCallback<T>;
  formFieldName: string;
};

export const updateRemoteFormField: <T>(
  form: FormikProps<any>,
  value: T,
  deffs: UpdateRemoteFormFieldDeff<T>[]
) => void = (form, value, deffs) => {
  const newValues = deffs.reduce((acum, aDeff) => {
    const newValue = aDeff.fieldExtractor(value);
    // funciona si el valor a reemplazar es un valor simple (string, number, etc)
    if (shallowEqual(form.values[aDeff.formFieldName], newValue)) {
      return acum;
    }
    return { ...acum, [aDeff.formFieldName]: newValue };
  }, {});

  const keys = Object.keys(newValues);
  switch (keys.length) {
    case 0:
      break;
    case 1:
      form.setFieldValue(keys[0], (newValues as any)[keys[0]]);
      break;
    default:
      form.setValues({ ...form.values, ...newValues });
  }
};

export const formatterSueldoON = (val: any) => `${val}`.substring(0, 8).replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const formatterCuitAndCuilON = (val: any) => {
  const newVal = Array.from(val).map((item: any, index: any) => {
    const digits = index + 1;
    const separatorType = 2;
    const separatorDigitVerificator = 10;

    if (digits === separatorType) {
      return `${item}-`;
    }
    if (digits === separatorDigitVerificator) {
      return `${item}-`;
    }
    return item;
  });
  return newVal.join('');
};

export const parserSueldoON = (val: any) => {
  if (!val) {
    return '';
  }
  return val.substring(0, 9).replace(/\$\s?|(\.*)/g, '');
};
export const newFormatedField = (content: string, formatted?: string) => ({
  value: content,
  formatted: formatted === undefined ? content : formatted
});

export const emptyFormatedFieldString = () => newFormatedField('');

export const emptyFormatedFieldNumber = () => newFormatedField('0');
