import i18next from 'i18next';

import { SWORN_DECLARATION_FIELDS } from './constants';

i18next.addResources('es', 'SwornDeclaration', {
  title: 'Declaración Jurada de Salud',
  titleDDJJ: 'Estado de Salud Actual',
  subtitleOTHERS: 'Solicitud de trámite',
  personalDataStep: 'Datos del titular',
  familyDataStep: 'Datos del familiar',
  fileDataStep: 'Carga de documentación',
  whichPathologies: '¿Cuál/es?',
  whichPathologiesTypes: '¿Cuál/es {{- type}}?',
  wichInterventionSimple: '¿Cuál fue la intervención estética?',
  whichIntervention: '¿Qué tipo de intervención? y ¿Cuál fue su diagnóstico?',
  whichInterventionType: '¿Cuál fue la {{other}} intervención {{type}}? y ¿Cuál fue su diagnóstico?',
  whichTreatmentType: '¿Cuál fue el tratamiento {{type}}? y ¿Cuál fue su diagnóstico?',
  whatYear: '¿En qué año?',
  whichPlaceholder: 'Contanos los detalles...',
  whichDiagnosis: '¿Cuál fue el diagnóstico?',
  whichHospitalization: '¿Cuál fue el motivo?',
  which: '¿Cuál?',
  whichMore: '¿Cuál/es?',
  howMany: '¿Cuántas?',
  howManyPlaceholder: 'Ej. 3',
  hospitalizationYear: 'Año de internación {{number}}:',
  hospitalizationYearQuestion: '¿Cuál fue el año de la internación {{number}}?',
  hospitalizationYearPlaceholder: 'Ej. 2007',
  treatmentYear: '¿En qué año?',
  treatmentDiagnostic: '¿Cuál fue el diagnóstico?',
  disabilityDateEmision: 'Fecha de emisión',
  disabilityDateExpire: 'Fecha de vencimiento',
  disabilityDiagnostic: '¿Cuál fue el diagnóstico?',
  [SWORN_DECLARATION_FIELDS.MEASURES.WEIGHT]: 'Ingresá tu peso',
  [`${SWORN_DECLARATION_FIELDS.MEASURES.WEIGHT}Family`]: 'Ingresá su peso',
  [`${SWORN_DECLARATION_FIELDS.MEASURES.WEIGHT}Sublabel`]: 'Indicalo en kilos',
  [`${SWORN_DECLARATION_FIELDS.MEASURES.WEIGHT}Placeholder`]: 'Ej. 70',
  [SWORN_DECLARATION_FIELDS.MEASURES.HEIGHT]: 'Ingresá tu altura',
  [`${SWORN_DECLARATION_FIELDS.MEASURES.HEIGHT}Family`]: 'Ingresá su altura',
  [`${SWORN_DECLARATION_FIELDS.MEASURES.HEIGHT}Sublabel`]: 'Indicala en centímetros',
  [`${SWORN_DECLARATION_FIELDS.MEASURES.HEIGHT}Placeholder`]: 'Ej. 167',
  [SWORN_DECLARATION_FIELDS.GENDER.GENDER]: 'Seleccioná tu sexo',
  [`${SWORN_DECLARATION_FIELDS.GENDER.GENDER}Family`]: 'Seleccioná su sexo',
  [`${SWORN_DECLARATION_FIELDS.GENDER.GENDER}Question`]: '¿Cuál es tu sexo?',
  [SWORN_DECLARATION_FIELDS.GENDER.PREGNANT]: '¿Estás cursando un embarazo?',
  [`${SWORN_DECLARATION_FIELDS.GENDER.PREGNANT}Family`]: '¿Está cursando un embarazo?',
  [SWORN_DECLARATION_FIELDS.GENDER.LAST_MENSTRUATION]:
    'Por favor, ingresá la fecha de tu última menstruación:',
  [`${SWORN_DECLARATION_FIELDS.GENDER.LAST_MENSTRUATION}Family`]: 'Por favor, ingresá la fecha de su última menstruación:',
  [`${SWORN_DECLARATION_FIELDS.GENDER.LAST_MENSTRUATION}None`]: 'No aplica',
  [`${SWORN_DECLARATION_FIELDS.GENDER.LAST_MENSTRUATION}Placeholder`]: '01/01/2020',
  [`${SWORN_DECLARATION_FIELDS.GENDER.LAST_MENSTRUATION}Question`]: '¿Cuál es la fecha de tu última menstruación?',
  [SWORN_DECLARATION_FIELDS.GENDER.NO_FUM_REASON]: 'Indicanos el motivo',
  [SWORN_DECLARATION_FIELDS.ANALISIS.LAST_YEAR_ANALISIS]: '¿Te realizaste estudios en el último año?',
  [`${SWORN_DECLARATION_FIELDS.ANALISIS.LAST_YEAR_ANALISIS}Family`]: '¿Se realizó análisis en el último año?',
  [SWORN_DECLARATION_FIELDS.ANALISIS.IS_NORMAL_ANALISIS]: '¿Los resultados fueron normales?',
  [SWORN_DECLARATION_FIELDS.ANALISIS.WHICH_ANALISIS]: '¿Cuál fue el diagnóstico?',
  [SWORN_DECLARATION_FIELDS.HOSPITALIZATIONS.HAD_HOSPITALIZATIONS]: '¿Tuviste internaciones?',
  [`${SWORN_DECLARATION_FIELDS.HOSPITALIZATIONS.HAD_HOSPITALIZATIONS}Family`]: '¿Tuvo internaciones?',
  [SWORN_DECLARATION_FIELDS.HOSPITALIZATIONS.WHICH_HOSPITALIZATIONS]: '¿Cuántas?',
  [SWORN_DECLARATION_FIELDS.SURGERIES.HAD_SURGERIES]: '¿Te realizaron cirugías?',
  [`${SWORN_DECLARATION_FIELDS.SURGERIES.HAD_SURGERIES}Family`]: '¿Le realizaron cirugías?',
  [SWORN_DECLARATION_FIELDS.SURGERIES.WHICH_SURGERIES]: 'Seleccioná las que correspondan:',
  [SWORN_DECLARATION_FIELDS.SURGERIES.HAD_PROSTHETIC]: '¿Tenés prótesis de rodilla, cadera y/o columna?',
  [`${SWORN_DECLARATION_FIELDS.SURGERIES.HAD_PROSTHETIC}Family`]: '¿Tiene prótesis de rodilla, cadera y/o columna?',
  [`${SWORN_DECLARATION_FIELDS.SURGERIES.HAD_PROSTHETIC}Sublabel`]: '(Excepto tornillos, arpones, clavijas)',
  [SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_PATHOLOGY]: '¿Tenés o tuviste alguna de las siguientes patologías?',
  [`${SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_PATHOLOGY}Family`]: '¿Tiene o tuvo alguna de las siguientes patologías?',
  [`${SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_PATHOLOGY}Sublabel`]: 'Seleccioná todas las opciones que correspondan:',
  [SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_VISION]: '¿Qué dificultad presentás en la visión?',
  [`${SWORN_DECLARATION_FIELDS.PATHOLOGY.HAD_VISION}Family`]: '¿Qué dificultad presenta en la visión?',
  [SWORN_DECLARATION_FIELDS.PATHOLOGY.WHICH_MYOPIA_VISION]: '¿Qué graduación tenés?',
  [`${SWORN_DECLARATION_FIELDS.PATHOLOGY.WHICH_MYOPIA_VISION}Family`]: '¿Qué graduación tiene?',
  [SWORN_DECLARATION_FIELDS.ADDICTION.HAD_ADDICTIONS]:
    '¿Tenés o tuviste algún tipo de adicción a las drogas y/o al alcohol?',
  [`${SWORN_DECLARATION_FIELDS.ADDICTION.HAD_ADDICTIONS}Family`]: '¿Tiene o tuvo algún tipo de adicción a las drogas y/o al alcohol?',
  [SWORN_DECLARATION_FIELDS.TREATMENTS.HAD_SOME_TREATMENT]:
    '¿Estás o estuviste realizando alguno de los siguientes tratamientos?',
  [`${SWORN_DECLARATION_FIELDS.TREATMENTS.HAD_SOME_TREATMENT}Family`]: '¿Está o estuvo realizando alguno de los siguientes tratamientos?',
  [SWORN_DECLARATION_FIELDS.TREATMENTS.HAD_SOME_TREATMENT_PLANNED]:
    '¿Tenés planeado realizar algún tratamiento, práctica o intervención durante los próximos 12 meses?',
  [`${SWORN_DECLARATION_FIELDS.TREATMENTS.HAD_SOME_TREATMENT_PLANNED}Family`]: '¿Tiene planeado realizar algún tratamiento, práctica o intervención durante los próximos 12 meses?',
  [SWORN_DECLARATION_FIELDS.DISABILITY.HAD_DISABILITY]:
    '¿Tuviste, tenés o estás tramitando algún certificado de discapacidad?',
  [`${SWORN_DECLARATION_FIELDS.DISABILITY.HAD_DISABILITY}Family`]: '¿Tuvo, tiene o está tramitando algún certificado de discapacidad?',
  [SWORN_DECLARATION_FIELDS.LAST_PATHOLOGY.HAD_PATHOLOGY_LAST]:
    '¿Padecés alguna/s patología/s además de las mencionadas anteriormente?',
  [`${SWORN_DECLARATION_FIELDS.LAST_PATHOLOGY.HAD_PATHOLOGY_LAST}Family`]: '¿Padece alguna/s patología/s además de las mencionadas anteriormente?'
});
