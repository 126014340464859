import React from 'react';
import i18next from 'i18next';
import { useSelector } from '~contexts/UserContext';

import styles from './styles.module.scss';

const CURRENT_YEAR = new Date().getFullYear();

function GeneralFooter() {
  const isMobile = useSelector(state => state.settings.isMobile);
  return (
    <footer className={`full-width row center middle ${styles.container}`}>
      <p className={`text-light-normal text-xsmall text-pre-wrap ${styles.footerDescription}`}>
        {i18next.t(`Footer:footerDescription${isMobile ? 'Mobile' : ''}`, { year: CURRENT_YEAR })}
      </p>
    </footer>
  );
}
export default GeneralFooter;
