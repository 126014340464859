/* eslint-disable id-length */
import i18next from 'i18next';

i18next.addResources('es', 'Missing', {
  missingTitle: 'Página no disponible {{errorCode}}',
  incorrectInformationTitle: 'Información incorrecta',
  incorrectInformationSubtitle:
    'Nos indicaste que existen errores en los datos cargados en la solicitud. ¡No te preocupes!.',
  incorrectInformationContentExtra:
    'Vamos a contactarnos con tu empresa para que revise la información y puedas retomar la carga del formulario.',
  missingSubtitle: 'Ocurrió un error.',
  ourWebsite: 'nuestro sitio web',
  thanks: '¡Muchas gracias!',
  missingContent:
    'Te pedimos disculpas por las molestias ocasionadas. Intentá ingresar de nuevo en unos minutos.',
  missingContentExtra:
    'Si tenés dudas sobre el proceso de afiliación, por favor, contactate con tu asesor comercial.',
  tokenExpiredSubtitle: 'Este enlace expiró.',
  tokenInvalidSubtitle: 'Este enlace es inválido.',
  tokenContent: '¡Hola! La página a la que querés ingresar ya no está disponible.',
  tokenContentExtra:
    'Si querés asociarte a OSDE, por favor, contactate con tu asesor comercial para solicitarle un nuevo enlace.'
});
