import React from 'react';
import { i18nStringType } from '~utils/types';

import styles from './styles.module.scss';

interface Props {
  children?: JSX.Element | i18nStringType;
}

function SectionParagraph({ children }: Props) {
  return (
    <div className={`max-width-container m-top-6 ${styles.container}`}>
      <div className={styles.subContainer}>
        <p className={`text-color-dark-gray text-light-normal text-pre-wrap ${styles.paragraph}`}>
          {children}
        </p>
      </div>
    </div>
  );
}

export default SectionParagraph;
