/* eslint-disable quotes */
import i18next from 'i18next';

i18next.addResources('es', 'FinishF1', {
  disclaimerAccept: 'Acepto las condiciones',
  finish: 'Ya falta poco',
  disclaimerFirst: `Los datos aportados forman parte de un banco de datos de OSDE, con domicilio en L. N. Alem 1067 piso 9 CABA y serán tratados para brindar servicios a los asociados. El titular de los datos personales no se encuentra obligado a proporcionar datos sensibles. Asimismo, el titular se responsabiliza por la exactitud de los datos proporcionados. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a 6 meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25.326. La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.

Declaro que he tomado conocimiento y en consecuencia acepto las `,
  terms: 'condiciones generales',
  disclaimerSecond: ` obrantes al dorso o adjuntas a la presente solicitud de afiliación y las normas generales contenidas en la cartilla de prestadores del plan de servicio elegido, cuyo ejemplar me ha sido entregado.
  
Declaro bajo juramento que los datos que constan en esta solicitud de afiliación - el que comprende los antecedentes de salud - son verdaderos y que la consecuencia de no proporcionarlos correctamente corren por cuenta de quien suscribe.

Podrá consultar las políticas de privacidad en `,
  web: 'www.osde.com.ar',
  disclaimerThird: `.

Cesión de datos personales: OSDE no comercializa datos personales de ningún tipo bajo ninguna circunstancia. A continuación se detallan los terceros con quienes podemos compartir, transferir o enviar datos a terceros y sus motivos: (i) prestadores y proveedores: para brindar servicio a nuestros socios; (ii) entidades gubernamentales y organismos de control: para el cumplimiento de las reglamentaciones establecidas; (iii) Grupo OSDE: para la mejora del servicio brindado a nuestros socios.
  `,
  disclaimerFirstDDJJ: `Los datos aportados forman parte de un banco de datos de OSDE, con domicilio en L. N. Alem 1067 piso 9 CABA. El titular de los datos personales no se encuentra obligado a proporcionar datos sensibles. Asimismo, el titular se responsabiliza por la exactitud de los datos proporcionados. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a 6 meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25.326. La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.

Declaro que he tomado conocimiento y en consecuencia acepto las `,
  disclaimerSecondDDJJ: `.

Declaro bajo juramento que los datos que constan en esta solicitud de información - el que comprende los antecedentes de salud - son verdaderos y que la consecuencia de no proporcionarlos correctamente corren por cuenta de quien suscribe.

Podrá consultar las políticas de privacidad en `,
  disclaimerThirdDDJJ: `.

Cesión de datos personales: OSDE no comercializa datos personales de ningún tipo bajo ninguna circunstancia.
  `,
  disclaimerBinary: `Para finalizar tu solicitud de afiliación te pedimos que leas y aceptes cada apartado de los Términos y condiciones específicos del plan solicitado.`,
  binaryDisclaimer: 'Términos y condiciones para el Plan {{-plan}}'
});
