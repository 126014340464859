import i18next from 'i18next';

i18next.addResources('es', 'Footer', {
  footerDescription:
    '©OSDE {{year}} - Todos los derechos reservados - Av. Leandro N. Alem 1067 - Piso 9 - C1001AAF - Buenos Aires - 0800 555 OSDE (6733)',
  footerDescriptionMobile: `©OSDE {{year}}
Todos los derechos reservados
Av. Leandro N. Alem 1067 - Piso 9
C1001AAF - Buenos Aires
0800 555 OSDE (6733)`
});
