import i18next from 'i18next';

i18next.addResources('es', 'Beneficiary', {
  beneficiaryTitle: 'Solicitud de afiliación',
  thanks: '¡Muchas gracias!',
  beneficiaryContent:
    'Nos indicaste que no solicitaste agregar a ningún beneficiario a tu plan.',
  beneficiaryContentExtra:
    'Ante cualquier consulta, por favor, contactate con tu asesor comercial.'
  });
