import { GenericObjectInterface } from '~utils/types';

export const FORM_INPUT_TYPES: GenericObjectInterface<string> = {
  TEXT: 'input',
  TEXT_AREA: 'textarea',
  PASSWORD: 'password',
  NUMBER: 'number'
};

export enum DATE_INPUT_TYPES {
  DATE = 'date',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

type FormatSectionType = { starts: number; ends: number };
export const INPUT_FORMATS: GenericObjectInterface<Array<FormatSectionType>> = {
  cuil: [
    { starts: 0, ends: 2 },
    { starts: 2, ends: 10 },
    { starts: 10, ends: 11 }
  ],
  dni: [
    { starts: 0, ends: 2 },
    { starts: 2, ends: 5 },
    { starts: 5, ends: 8 }
  ],
  creditCard: [
    { starts: 0, ends: 4 },
    { starts: 4, ends: 8 },
    { starts: 8, ends: 12 },
    { starts: 12, ends: 16 },
    { starts: 16, ends: 19 }
  ],
  cbu: [{ starts: 0, ends: 22 }],
  accountNumber: [{ starts: 1, ends: 16 }],
  street: [{ starts: 0, ends: 10 }],
  floor: [{ starts: 0, ends: 4 }],
  phone: [{ starts: 0, ends: 15 }],
  zipCode: [{ starts: 0, ends: 4 }],
  heightAndWeight: [{ starts: 0, ends: 4 }],
  year: [{ starts: 0, ends: 4 }]
};

export const INPUT_NORMALIZERS: GenericObjectInterface<any> = {
  cuil: { length: 11 },
  nroTramite: { min: 10, max: 11 },
  dni: { min: 7, max: 8 },
  creditCard: { min: 14, max: 19 },
  salary: { min: 5, max: 8 },
  cbu: { length: 22 },
  accountNumber: { min: 1, max: 16 },
  street: { min: 1, max: 10 },
  floor: { min: 1, max: 4 },
  phone: { min: 10, max: 15 },
  zipCode: { length: 4 },
  heightAndWeight: { length: 4 },
  year: { length: 4 }
};

export const INPUT_SEPARATORS: GenericObjectInterface<string> = {
  hyphen: '-',
  dot: '.',
  space: ' ',
  comma: ',',
  none: ''
};

export const F1_FIELDS = {
  INFO_DOCUMENT: {
    TRAMITE_NUMBER: 'tramiteNumber',
    PASSPORT_EXPIRATION: 'passportExpiration'
  },
  PLAN_DATA: {
    plan: 'plan',
    companyPlan: 'companyPlan'
  },
  PERSONAL_DATA: {
    name: 'name',
    surname: 'surname',
    birthDate: 'birthDate',
    gender: 'gender',
    relationship: 'relationship',
    nationality: 'nationality',
    cuil: 'cuil',
    documentType: 'documentType',
    documentNumber: 'documentNumber',
    email: 'email',
    maritalStatus: 'maritalStatus',
    profession: 'profession',
    taxCondition: 'taxCondition',
    hasCUIL: 'hasCUIL',
    contactPhoneNumber: 'contactPhoneNumber',
    otp: 'otp'
  },
  FAMILY_DATA: {
    couple: 'couple',
    sons: 'sons'
  },
  CONTACT_DATA: {
    street: 'street',
    streetNumber: 'streetNumber',
    floor: 'floor',
    apartment: 'apartment',
    district: 'district',
    country: 'country',
    province: 'province',
    department: 'department',
    zipCode: 'zipCode',
    contactNumberPrimary: 'contactNumberPrimary',
    contactNumberSecondary: 'contactNumberSecondary'
  },
  JOB_DATA: {
    jobs: 'jobs'
  },
  PAYMENT_DATA: {
    payment: 'payment',
    creditCardValidation: 'creditCardValidation',
    creditCardName: 'creditCardName',
    creditCardNumber: 'creditCardNumber',
    creditCardHoldername: 'creditCardHoldername',
    cbu: 'cbu',
    creditCardExpiration: 'creditCardExpiration'
  },
  BANK_DATA: {
    cbuRA: 'cbuRA',
    ownAccount: 'ownAccount',
    ownAccountOption: 'ownAccountOption',
    ownerName: 'ownerName',
    ownerSurname: 'ownerSurname',
    ownerCuil: 'ownerCuil',
    accountNumber: 'accountNumber'
  },
  HEALTH_DATA: {
    healthCoverage: 'healthCoverage',
    anotherCoverage: 'anotherCoverage',
    hasLifeInsurance: 'hasLifeInsurance',
    lifeInsurance: 'lifeInsurance',
    hasRetirementInsurance: 'hasRetirementInsurance',
    retirementInsurance: 'retirementInsurance',
    benefits: 'benefits',
    other: 'other',
    otherBenefits: 'otherBenefits'
  }
};

export const OTHER_FIELDS = {
  INFO_DOCUMENT: {
    TRAMITE_NUMBER: 'tramiteNumber',
    PASSPORT_EXPIRATION: 'passportExpiration'
  },
  PLAN_DATA: {
    plan: 'plan',
    companyPlan: 'companyPlan'
  },
  PERSONAL_DATA: {
    name: 'name',
    surname: 'surname',
    birthDate: 'birthDate',
    gender: 'gender',
    relationship: 'relationship',
    nationality: 'nationality',
    cuil: 'cuil',
    documentType: 'documentType',
    documentNumber: 'documentNumber',
    email: 'email',
    maritalStatus: 'maritalStatus',
    profession: 'profession',
    taxCondition: 'taxCondition',
    hasCUIL: 'hasCUIL'
  },
  OTHER_DATA: {
    observations: 'observations',
    file: 'file'
  }
};

export const JOB_DATA_FIELDS = {
  companyName: 'companyName',
  cuit: 'cuit',
  grossSalary: 'grossSalary'
};

export const COMMON_FIELDS = {
  FILES: {
    DNI_FILES: 'dniFiles',
    EXTRA_FILES: 'extraFiles',
    FAMILY_MEMBER_FILES: 'familyMemberFiles'
  }
};
