import i18next from 'i18next';

i18next.addResources('es', 'DatosDeEmpresa', {
  name: 'Razón social',
  cuit: 'CUIT',
  condition: 'Condición ante el IVA',
  activity: 'Rubro/Actividad',
  bussinesName: 'Razón social del grupo empresarial',
  bussinesCuit: 'CUIT',
  link: '¿Cómo saber mi número de rubro en la AFIP?',
  cuitPlaceHolder: '00-00000000-0',
  namePlaceHolder: 'Ingrese la razón social de la empresa',
  conditionPlaceHolder: 'Selecciona la condicion',
  activityPlaceHolder: '000000',
  bussinesNamePlaceHolder: 'Ingrese la razon social del grupo empresarial',
  bussinesCuitPlaceHolder: 'Ingrese CUIT',
  emailClarification: `Acepto recibir en la dirección de e-mail informada todas las comunicaciones administrativas como las de carácter legal emitidas por OSDE, como el envío de facturación, notificaciones sobre modificaciones en la cuota mensual, novedades sobre el servicio, etcétera, independientemente del uso de otros medios como el domicilio informado o teléfonos celulares.
Los datos de contacto informados tendrán validez hasta tanto comunique cambios a través de los canales habilitados.`
});

i18next.addResources('es', 'DatosApoderado', {
  firstNamePlaceholder: 'Ingrese el nombre',
  lastNamePlaceholder: 'Ingrese el apellido',
  cuil: 'CUIT',
  cuilPlaceHolder: '00-00000000-0',
  documentTypePlaceholder: 'Seleccione un tipo',
  documentNumber: 'Número de documento',
  phoneNumberPlaceholder: 'Número de teléfono',
  emailPlaceholder: 'Ingrese Email',
  tramitNumber: 'Número de tramite',
  passportExpirationDate: 'dd/mm/YYYY',
  emailClarification:
    'Acepto recibir en la dirección de e-mail informada todas las comunicaciones administrativas como las de carácter legal emitidas por OSDE, como el envío de facturación, notificaciones sobre modificaciones en la cuota mensual, novedades sobre el servicio, etcétera, independientemente del uso de otros medios como el domicilio informado o teléfonos celulares.',
  emailClarification2:
    'Los datos de contacto informados tendrán validez hasta tanto comunique cambios a través de los canales habilitados.',
  checkLegal:
    'Doy fe que soy la persona responsable de llevar adelante la aceptación de la oferta de servicios efectuada por OSDE y de llevar a cabo la adhesión de los planes superadores ofrecidos por OSDE para todos los empleados de la empresa por la cual me estoy presentando. Acredito tal condición con la documentación adjunta.'
});

i18next.addResources('es', 'DocumentType', {
  dni: 'DNI',
  passport: 'Pasaporte',
  dniShort: 'DNI',
  passportShort: 'Pasaporte'
});

i18next.addResources('es', 'CorporativeDiscounts', {
  sameDiscount:
    'Los planes Corporativos tiene un descuento comercial del {{ discount }}%  por los próximos 12 meses.  La vigencia del porcentaje promocional pautado expira el 30 de diciembre de cada año una vez finalizado los 12 meses iniciales. Su renovación es automática a partir del siguiente día hábil. La oferente deberá comunicar a la empresa el fin o la disminución del beneficio con una antelación de 90 días corridos. La bonificación podrá ser modificada en el caso que la empresa deje de tener exclusividad con OSDE, realice cambios parciales en su nómina o no cumpla los pagos a término.',
  perPlanDiscount:
    'El plan Corporativo 260 tiene un descuento comercial del {{ discount8260 }}%  por los próximos 12 meses.  El plan Corporativo 360 tiene un descuento comercial del {{ discount8360 }}%  por los próximos 12 meses. El plan Corporativo 430 tiene un descuento comercial del {{ discount8430 }}%  por los próximos 12 meses. La vigencia del porcentaje promocional pautado expira el 30 de diciembre de cada año una vez finalizado los 12 meses iniciales. Su renovación es automática a partir del siguiente día hábil. La oferente deberá comunicar a la empresa el fin o la disminución del beneficio con una antelación de 90 días corridos. La bonificación podrá ser modificada en el caso que la empresa deje de tener exclusividad con OSDE, realice cambios parciales en su nómina o no cumpla los pagos a término.'
});
