import i18next from 'i18next';

i18next.addResources('es', 'SummaryValidationOthers', {
  summaryTitle: 'Formulario de datos',
  summaryFirstDescription:
    '{{- currentName}}, por favor, verificá que toda la información que completaste sea correcta.',
  summarySecondDescription: 'Si fuera necesario, podés hacer las modificaciones correspondientes.',
  buttonConfirm: 'Sí, la información es correcta',
  buttonReject: 'Los datos son incorrectos',
  plan: 'Plan OSDE:',
  notAnswered: 'No contestado',
  yes: 'Sí',
  no: 'No',
  couple: 'Pareja',
  sons: 'Hijo/a',
  name: 'Nombre/s:',
  surname: 'Apellido/s:',
  birthDate: 'Fecha de nacimiento:',
  gender: 'Sexo:',
  nationality: 'Nacionalidad:',
  cuil: 'CUIL:',
  documentType: 'Tipo de documento:',
  documentNumber: 'Número:',
  email: 'Correo electrónico:',
  notAdded: '(No agregado)'
});
