import i18next from 'i18next';

i18next.addResources('es', 'FileStepCommon', {
  dni: `• DNI

Foto del frente y dorso de tu documento.
Si no contás con DNI, adjuntá una foto de la primera página del pasaporte.`,
  dniMember: `• DNI

Foto del frente y dorso del documento.
Si no cuenta con DNI, adjuntá una foto de la primera página del pasaporte.`,
  couple: '• Certificado de matrimonio o de convivencia.',
  son: `• DNI y/o Partida de nacimiento

Foto del frente y dorso del documento.
Si no cuenta con DNI, adjuntá una foto de la primera página del pasaporte.`,
  sonUnderGuard: `
• Resolución judicial`,
  dniVB: `• DNI 
  
No es necesario que adjuntes tu documento.
Ya lo capturamos en el proceso de validación de identidad.`
});

i18next.addResources('es', 'FileStep', {
  fileTitle: 'Por favor, adjuntá los siguientes documentos:',
  documentSubtitle: i18next.t('FileStepCommon:dni'),
  monotaxSubtitle: `Monotributista

• Formulario 184.
• Formulario 152.
• Último ticket de pago.`,
  monotaxSubtitleBenef: `Monotributista

• Formulario 184.
• Formulario 152.`,
  monotaxSubtitleF1a: `Monotributista

• Formulario 184.`,
  monotaxSubtitleF1b: `
  • Formulario 152.`,
  monotaxSubtitleF1c: `
  • Último ticket de pago.`,

  companySubtitle: `Trabajo

• Certificado de trabajo o recibo de sueldo o alta de AFIP o alta temprana de todos tus trabajos declarados.`,
  coupleSubtitle: i18next.t('FileStepCommon:couple'),
  coupleSubtitleF1: `${i18next.t('FileStepCommon:dniMember')}`,
  sonSubtitle: i18next.t('FileStepCommon:son'),
  sonSubtitleF1: `${i18next.t('FileStepCommon:dniMember')}

${i18next.t('FileStepCommon:son')}`,
  sonUnderGuard: i18next.t('FileStepCommon:sonUnderGuard'),
  fileAdviceExtension: 'Por favor, cargá las imágenes en formato PNG, JPEG o PDF.',
  fileAdviceSize: 'El peso de los archivos no deberá superar los 10MB.'
});

i18next.addResources('es', 'InputTramitNumber', {
  invalidTramitNumber: 'Ingresá entre 10 u 11 dígitos'
});

i18next.addResources('es', 'PlaceholderTramitNumber', {
  placeholder: 'Ingresá número de trámite'
});
