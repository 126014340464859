import i18next from 'i18next';

const TEN = 10;

const formatCardExpirationDate = (): string => {
  const currentDate = new Date();
  const currentMonth = currentDate.getUTCMonth() + 1;
  const formattedMonth = currentMonth < TEN ? `0${currentMonth}` : currentMonth;

  const currentYear = currentDate.getUTCFullYear();
  const formattedYear = currentYear.toString().substring(2);

  const expirationDate = `${formattedMonth}/${formattedYear}`;

  return expirationDate;
};

i18next.addResources('es', 'AffiliationForm', {
  title: 'Solicitud de afiliación',
  titleF1: 'Solicitud de modificación',
  titleDDJJ: 'Solicitud de información',
  titleOthers: 'Solicitud de trámite',
  name: 'Nombre',
  namePlaceholder: 'Ingrese nombre completo',
  surname: 'Apellido',
  surnamePlaceholder: 'Ingrese apellido completo',
  planStep: 'Plan OSDE',
  personalDataStep: 'Tus datos personales',
  knowBetterStep: 'Para conocerte mejor',
  familiyGroupStep: 'Tu grupo familiar',
  contactDataStep: 'Tus datos de contacto',
  othersDataStep: 'Datos de tu trámite',
  jobDataStep: 'Tu trabajo',
  paymentDataStep: 'Datos para el débito automático',
  bankDataStep: 'Datos para acreditación de reintegros',
  plan: '¿Qué plan de OSDE querés contratar?',
  planPlaceholder: 'Seleccioná un plan',
  street: 'Calle',
  streetPlaceholder: 'Ingresá la calle de tu domicilio',
  streetNumber: 'Número de domicilio',
  streetNumberPlaceholder: 'Ingresá el número',
  country: 'País',
  countryPlaceholder: 'Ingresá país',
  department: 'Localidad',
  departmentPlaceholder: 'Ingresá tu localidad',
  zipCode: 'Código postal',
  zipCodePlaceholder: 'Ingresá tu código postal',
  birthDate: 'Fecha de nacimiento',
  birthDatePlaceholder: '01/01/2020',
  gender: 'Sexo',
  male: 'Masculino',
  female: 'Femenino',
  nonBinary: 'No Binario',
  nationality: 'Nacionalidad',
  nationalityPlaceholder: 'Ingresá tu nacionalidad',
  cuil: 'CUIL',
  cuilPlaceholder: 'XX-XXXXXXXX-X',
  noCuil: 'No tengo CUIL',
  email: 'E-mail',
  emailPlaceholder: 'Ingresá tu dirección de email',
  profession: 'Profesión',
  professionPlaceholder: 'Seleccioná tu profesión',
  taxCondition: 'Condición ante el IVA',
  conditionPlaceholder: 'Seleccioná tu condición',
  maritalStatus: 'Estado civil',
  married: 'Casado/a',
  single: 'Soltero/a',
  divorced: 'Divorciado/a',
  widower: 'Viudo/a',
  coexistanceUnion: 'Unión convivencial',
  healthCoverage: '¿Cuál es tu cobertura de salud actual?',
  healthCoveragePlaceholder: 'Ingresá tu actual obra social',
  hasLifeInsurance: '¿Ya tenés un seguro de vida?',
  yes: 'Si',
  no: 'No',
  hasRetirementInsurance: '¿Ya tenés un seguro de retiro?',
  ambulance: '¿Tenés contratado servicios de ambulancias?',
  benefits: '¿En qué rubro te interesaría recibir beneficios de OSDE?',
  physicalActivity: 'Actividad física',
  personalCare: 'Cuidado personal',
  education: 'Educación',
  gastronomy: 'Gastronomía',
  tourism: 'Turismo',
  shopping: 'Compras',
  other: 'Otro',
  district: 'Información adicional',
  districtPlaceholder: 'Ingresá tu barrio, manzana, country, lote, etc',
  familyDataStep: 'Tu grupo familiar',
  couple: '¿Querés asociar a tu pareja?',
  coupleF1: '¿Querés incluir  a tu pareja?',
  payment: '¿Vas a pagar con tarjeta de crédito o a través de tu cuenta bancaria?',
  paymentF1Label: '¿Querés modificar tu cuenta actual?',
  creditCard: 'Tarjeta de crédito',
  bankAccount: 'Cuenta bancaria',
  paymentWithOut: 'No modificar',
  cbu: 'Número de CBU',
  cbuRefund: 'Quiero utilizar este mismo número de CBU para acreditar mis reintegros',
  cbuPlaceholder: 'Ingresá tu número de CBU',
  ownAccount: '¿Se trata de cuenta propia?',
  disclaimer:
    'Acepto recibir en la dirección de e-mail informada todas las comunicaciones administrativas como las de carácter legal emitidas por OSDE, como el envío de facturación, notificaciones sobre modificaciones en la cuota mensual, novedades sobre el servicio, etcétera, independientemente del uso de otros medios como el domicilio informado o teléfonos celulares. Los datos de contacto informados tendrán validez hasta tanto comunique cambios a través de los canales habilitados.',
  which: '¿Cuál?',
  lifeInsurancePlaceholder: 'Indicá tu seguro de vida',
  retirementInsurancePlaceholder: 'Seleccioná tu seguro de retiro',
  otherBenefitsPlaceholder: 'Indicá otro interés',
  floor: 'Piso',
  floorPlaceholder: 'Ingresá tu piso',
  apartment: 'Número de departamento',
  apartmentPlaceholder: 'Ingresá tu departamento',
  cuit: 'CUIT',
  creditCardName: 'Tarjeta',
  creditCardNumber: 'Número de la tarjeta',
  creditCardNumberPlaceholder: '0000 0000 0000 0000',
  creditCardHoldername: 'Nombre del titular',
  // eslint-disable-next-line id-length
  creditCardHoldernamePlaceholder: 'Como figura en la tarjeta',
  creditCardExpiration: 'Fecha de vencimiento',
  // creditCardExpPlaceholder: '01/20',
  creditCardExpPlaceholder: `${formatCardExpirationDate()}`,
  sons: '¿Querés asociar a tu/s hijo/s también?',
  sonsF1: '¿Querés incluir a tu/s hijo/s también?',
  addCouple: 'Agregar pareja',
  addSon: 'Agregar hijo',
  addAnotherSon: 'Agregar otro hijo',
  remove: 'Quitar de la afiliación',
  coupleData: 'Datos de pareja',
  sonData: 'Datos de tu hijo/a',
  province: 'Provincia',
  provincePlaceholder: 'Seleccioná tu provincia',
  contactNumberPrimary: 'Teléfono fijo',
  contactNumberSecondary: 'Teléfono celular',
  contactNumberPlaceholder: 'Ingresá número de teléfono',
  maritalStatusPlaceholder: 'Seleccioná tu estado civil',
  emailDisclaimer: `Acepto recibir en la dirección de e-mail informada todas las comunicaciones administrativas como las de carácter legal emitidas por OSDE, como el envío de facturación, notificaciones sobre modificaciones en la cuota mensual, novedades sobre el servicio, etcétera, independientemente del uso de otros medios como el domicilio informado o teléfonos celulares.
Los datos de contacto informados tendrán validez hasta tanto comunique cambios a través de los canales habilitados.`,
  cbuDisclaimer: `Por la presente informo/informamos a esta organización que a partir de la fecha, acepto/aceptamos operar con el sistema de acreditación automática en cuenta bancaria como medio de pago de los reintegros pedidos/presentados por mi parte a esta institución, solicitando que dichos importes se acrediten en la cuenta que aquí se detalla.
La acreditación de los reintegros en la cuenta indicada tendrá efectos cancelatorios y libera a OSDE de la responsabilidad por eventuales perjuicios que pudieran derivarse por depreciación monetaria, demoras en la extracción u otras consecuencias adversas ajenas a la Obra Social.`,
  fileDataStep: 'Carga de documentación',
  planPLA: 'Tu empresa contratará el siguiente plan:',
  phoneTooltipText: 'No te olvides de ingresar el código tu área (Ej. 011) seguido de tu número'
});

i18next.addResources('es', 'AffiliationFormOther', {
  titleOthers: 'Solicitud de trámite',
  personalDataStep: 'Tus datos personales',
  othersDataStep: 'Datos de tu trámite',
  fileDataStep: 'Carga de documentación'
});
