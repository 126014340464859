import React, { useReducer, useCallback } from 'react';
import Modal from 'react-modal';
import '../scss/application.scss';
import { reducer as userReducer, INITIAL_STATE, actionCreators } from '~contexts/UserContext/reducer';
import { reducer as individuoReducer, InitialState } from '~contexts/IndividuoContext/reducer';
import { reducer as merlinReducer, initialState as merlinInitalState } from '~contexts/MerlinContext/reducer';
import { Context } from '~contexts/UserContext';
import { Context as IndividuoContext } from '~contexts/IndividuoContext';
import { Context as MerlinContext } from '~contexts/MerlinContext';

import Routes from './components/Routes';
import { useSize } from './hooks/useSize';

Modal.setAppElement('#root');

function App() {
  const [userState, userDispatch] = useReducer(userReducer, INITIAL_STATE);
  const [individuoState, individuoDispatch] = useReducer(individuoReducer, InitialState);
  const [merlinState, merlinDispatch] = useReducer(merlinReducer, merlinInitalState);
  const setSize = useCallback((size: string) => {
    userDispatch(actionCreators.setSize(size));
  }, []);

  useSize(setSize);

  return (
    <Context.Provider value={{ state: userState, dispatch: userDispatch }}>
      <IndividuoContext.Provider value={{ state: individuoState, dispatch: individuoDispatch }}>
        <MerlinContext.Provider value={{ state: merlinState, dispatch: merlinDispatch }}>
          <Routes />
        </MerlinContext.Provider>
      </IndividuoContext.Provider>
    </Context.Provider>
  );
}

export default App;
