import i18next from 'i18next';

i18next.addResources('es', 'Terms', {
  title: 'Términos y condiciones',
  disclaimerFirst: `Los datos aportados forman parte de un banco de datos de OSDE, con domicilio en L. N. Alem 1067 piso 9 CABA y serán tratados para brindar servicios a los asociados. El titular de los datos personales no se encuentra obligado a proporcionar datos sensibles. Asimismo, el titular se responsabiliza por la exactitud de los datos proporcionados. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a 6 meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25.326. La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.

Declaro que he tomado conocimiento y en consecuencia acepto las `,
  terms: 'condiciones generales',
  disclaimerSecond:
    ' obrantes adjuntas a la presente solicitud de alta de empresa y las normas generales contenidas en la cartilla de prestadores del plan de servicio elegido, la cual podré consultar en la página web de OSDE (',
  disclaimerThird: `).

Doy fe que soy la persona responsable de llevar adelante la aceptación de la oferta de servicios efectuada por OSDE y de llevar a cabo la adhesión de los planes superadores ofrecidos por OSDE para todos los empleados de la empresa por la cual me estoy presentando. Acredito tal condición con la documentación adjunta. `,
  disclaimerSecondCorporative:
    ' obrantes adjuntas a la presente solicitud de alta de empresa y las normas generales contenidas en la cartilla de prestadores del plan de servicio elegido, la cual podré consultar en los siguientes enlaces ',
  disclaimerThirdCorporative: `.

Doy fe que soy la persona responsable de llevar adelante la aceptación de la oferta de servicios efectuada por OSDE y de llevar a cabo la adhesión de los planes superadores ofrecidos por OSDE para todos los empleados de la empresa por la cual me estoy presentando. Acredito tal condición con la documentación adjunta. `,
  web: 'www.osde.com.ar',
  webCartilla260: 'Cartilla 260',
  webCartilla360: 'Cartilla 360',
  webCartilla430: 'Cartilla 430',
  commaAndSpace: ', ',
  disclaimerAccept: 'Acepto las condiciones',
  finish: 'Finalizar'
});
