import i18next from 'i18next';
import Immutable from 'seamless-immutable';

/**
 * Receives an array of strings, and returns an obj with that strings as properties with that string as value.
 * E.G:
 * stringArrayToObject(['A', 'B', 'C']) // { A: 'A', B: 'B', C: 'C' }
 * @param {array} actionsArray array of values
 * @param {string} namespace prefix for the resulting values
 * @returns {object} (['A', 'B', 'C'], 'name') -> { A: '@name/A', B: '@name/B', C: '@name/C' }
 */
export function stringArrayToObject(actionsArray, namespace = '') {
  if (actionsArray.some(actionName => !actionName || typeof actionName !== 'string')) {
    throw new Error('Action names must be strings and must not be empty');
  }

  return new Immutable(actionsArray).asObject(actionName => [actionName, `${namespace}:${actionName}`]);
}

/**
 * Receives a length, and returns a new Array with indices on each position.
 * E. G:
 * arrayOfIndex(3) // [0, 1, 2]
 * This is for a reason: avoiding the use of for loops.
 * @param {number} length length of the array
 * @returns {array} [0, ..., length - 1]
 *
 * @see https://github.com/airbnb/javascript#iterators--nope
 */
export function arrayOfIndex(length) {
  // .fill(<something not undefined>) is required for map, map ignore undefined indexes
  return Array(length)
    .fill(null)
    .map((_, index) => index);
}

/**
 * Receives an array of strings and returns a string with array values translated separated by a comma
 * E.G:
 * stringArrayToTranslateString(['male', 'female'])
 * @param {array} stringArray array of string values
 * @param {string} i18StringClass prefix for translate the string value
 * @returns {string} (['male', 'female'], 'Gender') -> "Masculino, Femenino"
 */
export const stringArrayToTranslateString = (stringArray = [], i18StringClass = '') =>
  stringArray.map(value => i18next.t(`${i18StringClass}:${value}`)).join(', ');

// eslint-disable-next-line no-nested-ternary
export const sortByLabel = (a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0);

export const hasElements = arreglo => arreglo && arreglo.length > 0;
