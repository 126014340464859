import i18next from 'i18next';

i18next.addResources('es', 'Login', {
  login: 'Iniciar sesión',
  loginExplanation: 'Ingrese los siguientes datos',
  continue: 'Continuar',
  back: 'Volver',
  email: 'E-mail',
  emailPlaceholder: 'Ej: nombre@empresa.com',
  password: 'Contraseña',
  passwordPlaceholder: 'Ingrese su contraseña',
  passworkSubLabel: 'Texto de ayuda del input',
  error: 'Error en el campo',
  completeFlow: 'Flujo completo',
  termsAndConditions: 'Términos y condiciones',
  errorScreen: 'Vista de Error',
  generalConditions: 'Condiciones generales'
});
