import i18next from 'i18next';

i18next.addResources('es', 'UploadFiles', {
  uploadFile: 'Cargar archivos',
  errorValidFile: 'El formato del archivo no es válido.',
  errorValidSize: 'El tamaño del archivo supera el máximo de 10MB.',
  errorFileData: 'Ha ocurrido un error al subir los archivos.',
  errorDuplicatedName: 'Existe un archivo con el mismo nombre.',
  errorDeleteFile: 'Ha ocurrido un error al intentar eliminar el archivo.',
  uploadingFile: 'Cargando archivo. Una vez finalizada la carga vas a poder subir otro.',
  deletingFile: 'Eliminando archivo. Por favor esperá.'
});
