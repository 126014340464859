import i18next from 'i18next';
import { SURGERY_TYPES } from '~constants/general';

i18next.addResources('es', 'SurgeriesOptions', {
  [SURGERY_TYPES.CARDIOLOGY]: 'Cardiológica',
  [SURGERY_TYPES.ABDOMINAL]: 'Abdominal',
  [SURGERY_TYPES.TRAUMATOLOGY]: 'Traumatológica',
  [SURGERY_TYPES.AESTHETIC]: 'Estética',
  [SURGERY_TYPES.GINECOLOGY]: 'Ginecológica',
  [SURGERY_TYPES.NERVOUS]: 'Sistema nervioso',
  [SURGERY_TYPES.ENDOCRINE]: 'Sistema endocrino',
  [`which${SURGERY_TYPES.CARDIOLOGY}`]: 'cardiológica',
  [`which${SURGERY_TYPES.ABDOMINAL}`]: 'abdominal',
  [`which${SURGERY_TYPES.TRAUMATOLOGY}`]: 'traumatológica',
  [`which${SURGERY_TYPES.AESTHETIC}`]: 'estética',
  [`which${SURGERY_TYPES.GINECOLOGY}`]: 'ginecológica',
  [`which${SURGERY_TYPES.NERVOUS}`]: 'en el sistema nervioso',
  [`which${SURGERY_TYPES.ENDOCRINE}`]: 'en el sistema endocrino',
  [`which${SURGERY_TYPES.OTHER.toLowerCase()}`]: 'otra',
  [SURGERY_TYPES.OTHER]: 'Otras',
  deselect: 'Eliminar "{{which}}"'
});
