import { Tooltip } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '~assets/logo.svg';
import PATHS from '~components/Routes/paths';
import { VERSION_APP } from '~constants/application';

import styles from './styles.module.scss';

function GeneralHeader() {
  return (
    <div className={`full-width row center ${styles.navContainer}`}>
      <header className={`full-width row ${styles.container}`}>
        <NavLink to={PATHS.notRedirect}>
          <Tooltip title={`versión ${VERSION_APP}`} placement="right-end">
            <img src={Logo} className={styles.logo} alt="osde-logo" />
          </Tooltip>
        </NavLink>
      </header>
    </div>
  );
}
export default GeneralHeader;
