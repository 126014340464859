import i18next from 'i18next';
import { GenericObjectInterface, GenericObjectArray, i18nStringType, AFFILIATE_TYPES } from '~utils/types';
import { YES_NO_VALUES } from '~constants/selectOptions';
import { newFormatedField } from '~utils/form';

import { version } from '../../package.json';

import { JOB_DATA_FIELDS } from './fields';

export type F1DataObject = GenericObjectInterface<any>;

// Mapped API values for FAMILY_GROUP
export enum FAMILY_GROUP_TYPES {
  COUPLE = 'partner',
  SON = 'child'
}

// TODO: Improve this in the future
export const TERMS_V1 = '1.0.0';
export const VERSION_APP = version;

export const isStepFilled = (formStep: GenericObjectInterface<any>) => !!Object.keys(formStep || {}).length;

export const getOptionalField = (field: string, value: any, translatedValue?: i18nStringType) =>
  value && (value.value?.length || value.formatted?.length)
    ? { [field]: translatedValue || value.value }
    : {};

export const getConditionalField = (field: string, value: any, conditionalField: any) => {
  if (!conditionalField) {
    return {};
  }
  if (conditionalField.value === YES_NO_VALUES.NO) {
    return { [field]: YES_NO_VALUES.NO };
  }
  return { [field]: value?.value || YES_NO_VALUES.YES };
};

export const getPersonalDataValues = (personalData: F1DataObject) => ({
  firstName: personalData.name.value,
  lastName: personalData.surname.value,
  birthDate: personalData.birthDate.formatted,
  sex: i18next.t(`SelectOptions:${personalData.gender.value}`),
  nationality: personalData.nationality?.value,
  cuil: personalData.cuil?.formatted,
  documentNumber: personalData.documentNumber?.value?.toString(),
  ...getOptionalField(
    'documentType',
    personalData.documentType,
    i18next.t(`DocumentType:${personalData.documentType?.value}`)
  ),
  ...getOptionalField('documentNumber', personalData.documentNumber),
  ...getOptionalField('contactPhoneNumber', personalData.contactPhoneNumber),
  ...getOptionalField('email', personalData.email),
  ...getOptionalField('idIntegranteEnOrigen', personalData.idIntegranteEnOrigen)
});

export const getFormattedCuil = (cuil: any) => {
  if (!cuil) {
    return '';
  }
  return cuil.replace(/^(\d{2})(\d{8})(\d{1})$/, '$1-$2-$3');
};

// Mapped API values for PAYMENT_TYPES
export const getPaymentType = (type: 'bankAccount' | 'creditCard') =>
  ({
    bankAccount: 'bank_account',
    creditCard: 'credit_card'
  }[type]);

export const getBankAnotherOwners = (bankData: F1DataObject) => {
  let anotherOwners: GenericObjectArray = [];
  if (
    bankData.ownAccount.value === YES_NO_VALUES.YES ||
    bankData.ownAccount.value !== YES_NO_VALUES.NO ||
    bankData.ownAccount.value === 'Yes' ||
    bankData.ownAccountOption?.value === 'Idem'
  ) {
    return anotherOwners;
  }
  anotherOwners = anotherOwners.concat({
    firstName: bankData.ownerName.value,
    lastName: bankData.ownerSurname.value,
    cuil: bankData.ownerCuil.formatted
  });
  return anotherOwners;
  // if (bankData.secondOwner.value === YES_NO_VALUES.NO) {
  //   return anotherOwners;
  // }
  // return anotherOwners.concat({
  //   firstName: bankData.secondOwnerName.value,
  //   lastName: bankData.secondOwnerSurname.value,
  //   cuil: bankData.secondOwnerCuil.formatted
  // });
};

export const getJobsArrayDefault = (
  affiliationType: AFFILIATE_TYPES,
  // cuit_empresa viene de una ap externa por lo cual no está en camelcase
  // eslint-disable-next-line @typescript-eslint/camelcase
  { empresa, cuit_empresa, sueldo }: { empresa: string; cuit_empresa: string; sueldo?: number }
) =>
  [AFFILIATE_TYPES.DIRECT, AFFILIATE_TYPES.MONOTAX].includes(affiliationType)
    ? ''
    : [
        {
          key: '',
          cuit: newFormatedField(cuit_empresa),
          companyName: newFormatedField(empresa),
          ...(affiliationType === AFFILIATE_TYPES.DIRECT_GROUP
            ? {}
            : { grossSalary: newFormatedField(String(sueldo || 0)) })
        }
      ];

export const getJobsArray = (affiliationType: AFFILIATE_TYPES, jobData: F1DataObject) =>
  jobData.jobs.map((job: F1DataObject) => ({
    [JOB_DATA_FIELDS.companyName]: job.companyName.value,
    [JOB_DATA_FIELDS.cuit]: job.cuit.value,
    ...(affiliationType === AFFILIATE_TYPES.DIRECT_GROUP
      ? {}
      : { [JOB_DATA_FIELDS.grossSalary]: job.grossSalary?.value })
  }));

export const MAPPED_FORM = {
  personalData: {
    profession: 'profession',
    floor: 'floor',
    apartment: 'apartment',
    addressReference: 'addressReference',
    secondaryPhoneNumber: 'secondaryPhoneNumber',
    primaryPhoneNumber: 'primaryPhoneNumber'
  },
  payment: {
    creditCardName: 'creditCardName',
    creditCardNumber: 'creditCardNumber',
    creditCardExpirationDate: 'creditCardExpirationDate',
    creditCardHoldername: 'creditCardHoldername',
    cbuBankAccount: 'cbuBankAccount'
  },
  currentState: {
    healthCoverage: 'healthCoverage',
    anotherCoverage: 'anotherCoverage',
    lifeInsurance: 'lifeInsurance',
    healthRetire: 'healthRetire',
    benefits: 'benefits',
    anotherBenefits: 'anotherBenefits'
  }
};

export const CHANNEL_ENTRIES = {
  QUOTE: 'cotizador_web',
  LINK: 'link',
  BACKOFFICE: 'backoffice',
  AUTOGESTION: 'autogestion'
};
