import React from 'react';

import styles from './styles.module.scss';

interface Props {
  children: JSX.Element;
}

function SectionHeader({ children }: Props) {
  return (
    <div className={`max-width-container m-top-12 ${styles.container}`}>
      <div className={`column middle full-width ${styles.titleContainer}`}>
        <h2 className={`text-color-primary text-medium-normal ${styles.title}`}>{children}</h2>
      </div>
    </div>
  );
}

export default SectionHeader;
