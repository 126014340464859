/* eslint-disable react/no-multi-comp */
import React from 'react';
import { getTargetProperties } from '~utils/links';
import { GenericObjectInterface } from '~utils/types';

import SectionHeader from './components/SectionHeader';
import SectionParagraph from './components/SectionParagraph';

interface Props {
  children: JSX.Element;
}

interface LinkProps extends Props {
  href: string;
}

const headings: GenericObjectInterface<Function> = {
  2: (props: Props) => <SectionHeader {...props} />
};

export const renderers = {
  heading: ({ level, ...props }: { level: number; props: Props }) => {
    const Heading = headings[level];

    return <Heading {...props} />;
  },
  paragraph: (props: Props) => <SectionParagraph {...props} />,
  link: ({ href, children }: LinkProps) => (
    <a className="text-color-tertiary" href={`https://${href}`} {...getTargetProperties(false)}>
      {children}
    </a>
  )
};
