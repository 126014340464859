export interface MerlinState {
  openMerlinModal: boolean;
  canContinue: string;
}

export const initialState = {
  openMerlinModal: false,
  canContinue: ''
};

enum ActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  CAN_CONTINUE = 'CAN_CONTINUE'
}

interface OpenModal {
  type: ActionTypes.OPEN_MODAL;
}

interface CloseModal {
  type: ActionTypes.CLOSE_MODAL;
}

interface CanContinue {
  type: ActionTypes.CAN_CONTINUE;
  payload: string;
}

export const actionCreator = {
  openModal: () => ({
    type: ActionTypes.OPEN_MODAL,
    payload: ''
  }),
  closeModal: () => ({
    type: ActionTypes.CLOSE_MODAL,
    payload: ''
  }),
  canContinue: (can: string) => ({
    type: ActionTypes.CAN_CONTINUE,
    payload: can
  })
};

export type Action = OpenModal | CloseModal | CanContinue;

export const reducer = (state: MerlinState = initialState, action: Action): MerlinState => {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL:
      return {
        ...state,
        openMerlinModal: true
      };
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        openMerlinModal: false
      };
    case ActionTypes.CAN_CONTINUE:
      return {
        ...state,
        canContinue: action.payload
      };
    default:
      return state;
  }
};
