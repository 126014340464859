import { MAX_LENGTH } from '~constants/general';
import { COMMON_FIELDS } from '~constants/fields';

export const SWORN_DECLARATION_FIELDS = {
  INFO_DOCUMENT: {
    TRAMITE_NUMBER: 'tramiteNumber',
    PASSPORT_EXPIRATION: 'passportExpiration'
  },
  MEASURES: {
    HEIGHT: 'height',
    WEIGHT: 'weight'
  },
  GENDER: {
    GENDER: 'personalGender',
    PREGNANT: 'pregnant',
    LAST_MENSTRUATION: 'lastMenstruation',
    NO_FUM_REASON: 'noFumReason'
  },
  ANALISIS: {
    LAST_YEAR_ANALISIS: 'lastYearAnalisis',
    IS_NORMAL_ANALISIS: 'normalAnalisis',
    WHICH_ANALISIS: 'whichAnalisis'
  },
  HOSPITALIZATIONS: {
    HAD_HOSPITALIZATIONS: 'hadHospitalizations',
    WHICH_HOSPITALIZATIONS: 'whichHospitalizations',
    WHICH_HOSPITALIZATION_YEAR: 'whichHospitalizationYear',
    CAUSE_HOSPITALIZATION_YEAR: 'causeHospitalizationYear'
  },
  SURGERIES: {
    HAD_SURGERIES: 'hadSurgeries',
    WHICH_SURGERIES: 'whichSurgeries',
    WHICH_CARGIOLOGY: 'whichCardiology',
    WHICH_ABDOMINAL: 'whichAbdominal',
    WHICH_GINECOLOGY: 'whichGinecology',
    WHICH_NERVOUSE: 'whichNervous',
    WHICH_ENDOCRINE: 'whichEndocrine',
    WHICH_OTHER: 'whichSurgeryOther',
    WHICH_SURGERIES_YEAR: 'whichSurgeriesYear',
    WHICH_CARGIOLOGY_YEAR: 'whichCardiologyYear',
    WHICH_ABDOMINAL_YEAR: 'whichAbdominalYear',
    WHICH_GINECOLOGY_YEAR: 'whichGinecologyYear',
    WHICH_NERVOUSE_YEAR: 'whichNervousYear',
    WHICH_ENDOCRINE_YEAR: 'whichEndocrineYear',
    WHICH_OTHER_YEAR: 'whichSurgeryOtherYear',
    HAD_PROSTHETIC: 'hadProsthetic',
    WHICH_TRAUMATOLOGY: 'whichTraumatology',
    WHICH_TRAUMATOLOGY_YEAR: 'whichTraumatologyYear',
    WHICH_AESTHETIC: 'whichAesthetic',
    WHICH_AESTHETIC_YEAR: 'whichAestheticYear'
  },
  PATHOLOGY: {
    HAD_PATHOLOGY: 'hadPathology',
    HAD_VISION: 'hadVision',
    WHICH_OTHER_VISION: 'whichOtherVision',
    WHICH_HEREDITARY: 'whichHereditary',
    WHICH_PSYCHIATRIC: 'whichPsychiatric',
    WHICH_AUDITION: 'whichAudition',
    WHICH_MYOPIA_VISION: 'whichMyopiaVision'
  },
  ADDICTION: {
    HAD_ADDICTIONS: 'hadAddictions'
  },
  TREATMENTS: {
    HAD_SOME_TREATMENT: 'hadSomeTreatment',
    HAD_SOME_TREATMENT_PLANNED: 'hadSomeTreatmentPlanned',
    WHICH_SOME_TREATMENT_PLANNED: 'whichSomeTreatmentPlanned',
    TREATMENT_DIAGNOSTIC: 'treatmentDiagnostic',
    TREATMENT_YEAR: 'treatmentYear',
    WHICH_NEUROLINGUISTIC: 'whichNeurolinguistic',
    WHICH_NEUROLINGUISTIC_YEAR: 'whichNeurolinguisticYear',
    WHICH_PSYCHOMOTRICITY: 'whichPsychomotricity',
    WHICH_PSYCHOMOTRICITY_YEAR: 'whichPsychomotricityYear',
    WHICH_PSYCHOPEDAGOGY: 'whichPsychopedagogy',
    WHICH_PSYCHOPEDAGOGY_YEAR: 'whichPsychopedagogyYear',
    WHICH_THERAPY: 'whichTherapy',
    WHICH_THERAPY_YEAR: 'whichTherapyYear',
    WHICH_STIMULATION: 'whichStimulation',
    WHICH_STIMULATION_YEAR: 'whichStimulationYear'
  },
  DISABILITY: {
    HAD_DISABILITY: 'hadDisability',
    DISABILITY_DIAGNOSTIC: 'disabilityDiagnostic',
    DISABILITY_DATE_EMISION: 'disabilityDateEmision',
    DISABILITY_DATE_EXPIRE: 'disabilityDateExpire'
  },
  LAST_PATHOLOGY: {
    HAD_PATHOLOGY_LAST: 'hadPathologyLast',
    WHICH_PATHOLOGY_LAST: 'whichPathologyLast'
  },
  ...COMMON_FIELDS
};

export const HAD_SOME_TREATMENT_TYPES = {
  NEUROLINGUISTIC: 'neurolinguistic',
  PSYCHOMOTRICITY: 'psychomotricity',
  PSYCHOPEDAGOGY: 'psychopedagogy',
  THERAPHY: 'therapy',
  STIMULATION: 'stimulation',
  NONE: 'none'
};

export const WHICH_FIELDS_LENGTHS = {
  min: 1,
  max: MAX_LENGTH
};

export const MEASURE_STEP_VALIDATION = {
  min: 0,
  max: 999
};

export const HOSPITALIZATION_VALIDATION = {
  min: 1900
};
